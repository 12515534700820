import { Component, OnInit, ElementRef, ViewChild,EventEmitter,Output,} from '@angular/core';
import { Product } from "../../modals/product.model";
import { CartItem } from "../../modals/cart-item";
import { ProductService } from "../shared/services/product.service";
import { CartService } from "../shared/services/cart.service";
import { Router, NavigationEnd } from '@angular/router';
import { SidebarMenuService } from '../shared/sidebar/sidebar-menu.service';
import { SidenavMenu } from '../shared/sidebar/sidebar-menu.model';
import { ProductsService } from '../../services/products.service';
import { WishlistService } from '../shared/services/wishlist.service';
import { CommonService } from '../../services/common.service';
import { UserService} from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { PubSubService } from 'angular7-pubsub';
import { Observable, of } from 'rxjs';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.sass']
})
export class MainComponent implements OnInit {

  
  public sidenavMenuItems: Array<any>;
  myArraycategory = [];
  public currencies = [];
  public currency: any;
  public flags = [
   { name: "العربية", code: "ar", lang_image: "2c022d1fc0.png",},
   { name: "English", code: "en", lang_image: "2b871db038.svg",}
  ]

  public flag: any;
  mobile:string;
  email:string;
  currentUser:any;
  user_img='';
  existImg:boolean=false;
  userLogedIn:boolean=false;
  user_full_name='';
  usre_id:any;
  user_type='';
  namecurr:any;
  currancechang
  products: Product[];
  lang
  indexProduct: number;
  shoppingCartItems: CartItem[] = [];
  jhiyuigy
  public banners = [];

  wishlistItems: Product[] = [];
  flaglang;
  public url: any;
  public imgurl:string='';
  public userImgPath:string='';
  public defaultImgPath:string='';
  public langes = [];
  vvv
  navItems: SidenavMenu[] = [
    {
      displayName: 'home',
      iconName: 'recent_actors',
      children: [
        {
          displayName: 'Home-1',
          iconName: 'group',
          route: '/home/one'
        },
        {
          displayName: 'Home-2',
          iconName: 'speaker_notes',
          route: '/home/two',
        },
        {
          displayName: 'Home-3',
          iconName: 'feedback',
          route: '/home/three'
        },
        {
          displayName: 'home',
          iconName: 'feedback',
          route: '/home/four'
        }
      ]
    },
    {
      displayName: 'Products',
      iconName: 'feedback',
      route: '/home/products/id'
    },
    // {
    //   displayName: 'Shop',
    //   iconName: 'movie_filter',
    //   children: [
    //     {
    //       displayName: 'Computers',
    //       iconName: 'group',
    //       children: [
    //         {
    //           displayName: 'Laptops',
    //           iconName: 'person',
    //           route: 'michael-prentice',
    //         },
    //         {
    //           displayName: 'Cables',
    //           iconName: 'person',
    //           route: 'stephen-fluin',
    //         },
    //         {
    //           displayName: 'Monitors',
    //           iconName: 'person',
    //           route: 'mike-brocchi',
    //         },
    //         {
    //           displayName: 'Tablets',
    //           iconName: 'person',
    //           route: 'mike-brocchi',
    //         },
    //         {
    //           displayName: 'Headsets',
    //           iconName: 'person',
    //           route: 'mike-brocchi',
    //         }
    //       ]
    //     },
    //     {
    //       displayName: 'Tv & Audio',
    //       iconName: 'speaker_notes',
    //       children: [
    //         {
    //           displayName: 'Tv',
    //           iconName: 'star_rate',
    //           route: 'material-design'
    //         },
    //         {
    //           displayName: 'Audio',
    //           iconName: 'star_rate',
    //           route: 'what-up-web'
    //         },
    //         {
    //           displayName: 'Video',
    //           iconName: 'star_rate',
    //           route: 'my-ally-cli'
    //         },
    //         {
    //           displayName: 'Dvd',
    //           iconName: 'star_rate',
    //           route: 'become-angular-tailer'
    //         }
    //       ]
    //     },
    //     {
    //       displayName: 'Phones',
    //       iconName: 'feedback',
    //       children: [
    //         {
    //           displayName: 'Mobile phones',
    //           iconName: 'star_rate',
    //           route: 'material-design'
    //         },
    //         {
    //           displayName: 'Power Bank',
    //           iconName: 'star_rate',
    //           route: 'what-up-web'
    //         },
    //         {
    //           displayName: 'Memory Cards',
    //           iconName: 'star_rate',
    //           route: 'my-ally-cli'
    //         },
    //         {
    //           displayName: 'Accesories',
    //           iconName: 'star_rate',
    //           route: 'become-angular-tailer'
    //         }
    //       ]
    //     },
    //     {
    //       displayName: 'Electronics',
    //       iconName: 'feedback',
    //       children: [
    //         {
    //           displayName: 'Washing Machines',
    //           iconName: 'star_rate',
    //           route: 'material-design'
    //         },
    //         {
    //           displayName: 'Water heater',
    //           iconName: 'star_rate',
    //           route: 'what-up-web'
    //         },
    //         {
    //           displayName: 'Cookers',
    //           iconName: 'star_rate',
    //           route: 'my-ally-cli'
    //         },
    //         {
    //           displayName: 'Cold stores',
    //           iconName: 'star_rate',
    //           route: 'become-angular-tailer'
    //         }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   displayName: 'Blog',
    //   iconName: 'report_problem',
    //   children: [
    //     {
    //       displayName: 'Blog List',
    //       iconName: 'group',
    //       route: '/blog/blog-list'
    //     },
    //     {
    //       displayName: 'Blog Columns',
    //       iconName: 'speaker_notes',
    //       route: '/blog/blog-column',
    //     },
    //     {
    //       displayName: 'Blog Details',
    //       iconName: 'feedback',
    //       route: '/blog/blog-details'
    //     }
    //   ]
    // },
    // {
    //   displayName: 'Pages',
    //   iconName: 'report_problem',
    //   children: [
    //     {
    //       displayName: 'About Us',
    //       iconName: 'group',
    //       route: '/pages/about'
    //     },
    //     {
    //       displayName: 'FAQ',
    //       iconName: 'speaker_notes',
    //       route: '/pages/faq',
    //     },
    //     {
    //       displayName: 'Contact',
    //       iconName: 'feedback',
    //       route: '/pages/contact'
    //     },
    //     {
    //       displayName: 'Wishlist',
    //       iconName: 'group',
    //       route: '/pages/wishlist'
    //     },
    //     {
    //       displayName: 'Compare',
    //       iconName: 'speaker_notes',
    //       route: '/pages/compare',
    //     },
    //     {
    //       displayName: 'Checkout',
    //       iconName: 'feedback',
    //       route: '/pages/checkout'
    //     },
    //     {
    //       displayName: 'Cart',
    //       iconName: 'group',
    //       route: '/pages/cart'
    //     },
    //     {
    //       displayName: 'My Account',
    //       iconName: 'speaker_notes',
    //       route: '/pages/my-account',
    //     },
    //     {
    //       displayName: '404',
    //       iconName: 'feedback',
    //       route: '/pages/error'
    //     }
    //   ]
    // },
    // {
    //   displayName: 'Contact',
    //   iconName: 'feedback',
    //   route: '/pages/contact'
    // }
  ];
  public product        :   Observable<Product[]> = of([]);


  constructor(public router: Router,public user:UserService, private productstore: ProductsService, private cartService: CartService, public translate: TranslateService,
    private pubsub: PubSubService, private wishlistService: WishlistService, public sidenavMenuService: SidebarMenuService, private common: CommonService) {
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
     this.product = this.wishlistService.getProducts();
    this.product.subscribe(products => this.wishlistItems = products);
   
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    })
    this.imgurl =this.common._hostName + 'uploads/languages/';
    this.defaultImgPath =  this.common._hostName + 'uploads/no_image.jpg';
    this.userImgPath =  this.common._hostName + 'uploads/users/';
   
  }

  
  getAllCategories(lang_code) {
   
    this.productstore.getAllCategories(lang_code).subscribe((data) => {
      if (data) {
       
        // this.materialphotos = data.data;
        this.myArraycategory = data.data;

        for(let i=0;i < this.myArraycategory.length;i++ ){

          this.myArraycategory[i].route='/home/category/'+this.myArraycategory[i].id;

        }

        // this.subcategory=data.data[1];
        // this.subcategory=data.;
        //  console.log("NNNNNNNNNNNNNNNNN6666",this.myArraycategory);
     

        // console.log("NNN6666",this.subcategory);

        // console.log("NNN6666",this.subcategory);
        // this.dropdown.push(this.subcategory);
        // console.log(this.dropdown,'wwwwwwwwwwww')

        // // let  dropdown = {
        // //   // id: number;
        // //   // name: string;
         
        // //   // sub_categories: [];
        // //   // img: string;
        // // };
      
        // let array = [];
        // for(let key in this.subcategory){
        //  if(this.subcategory.hasOwnProperty(key)){
        //    array.push(this.subcategory[key]);
        //  }
        // }
        // console.log(array,'kkk');
        // console.log(array[(2)],'ggggg');
      }
      // let arr = [];
      // Object.keys(data.data).map(function (key) {
      //   arr.push({ [key]:data.data[key] })
      //   // this.myArraycategory=arr;
      //   return arr;

      // });
      // console.log('Object=',data.data)
      // console.log('Array=', arr)
    });
  }


  ngAfterViewInit() {

    //  this.common.exchangcurrancies("CNY",this.currency).subscribe((data) => {
      
    //   if (data) {
        
    //     this.namecurr=data['CNY_'+this.currency]
    //     console.log("exchangcurrancies main componant",this.namecurr);
    //    localStorage.setItem("cur",this.namecurr);
   
    //     console.log("name curr in lopcal storage",localStorage.getItem("cur"));


    //   }
    // });
  }
  getAllLanguages() {
    this.common.getAllLanguages().subscribe((data) => {
      if (data.data) {
        this.langes = data.data;
        // console.log("langes", this.langes);
      }
    });
  }
  getAllCurrencies() {
    this.common.getAllCurrencies().subscribe((data) => {
      if (data.data) {
          this.currencies = data.data;
          if(localStorage.getItem("currency_code")==null){
            this.currency=this.currencies[2].code;
            localStorage.setItem("currency_code",this.currency);
            localStorage.setItem("currency_rate",this.currencies[2].rate);
          }
          else{
            this.currency=localStorage.getItem("currency_code");
          }
         
        // console.log("get All Currencies main component", this.currencies);
      }
    });
  }
  exchangcurrancies(currency) {

      this.currency = currency.code;
      console.log("currency object main component",currency);
      localStorage.setItem("currency_code",this.currency);
       localStorage.setItem("currency_rate",currency.rate);
       location.reload();
      
  }

  private changeName(name: string): void {
    this.user_full_name = name;
    this.checkOuth();
}


checkOuth(){
if(this.user.getToken() !== null){
  this.userLogedIn=true;
  this.currentUser=JSON.parse(this.user.getCurrentUser());
  this.usre_id=this.currentUser.id;
  this.user_type=this.currentUser.user_type;
  this.user_img=this.currentUser.img;
  this.existImg=this.user_img !='' && this.user_img != null?true:false;
  this.user_full_name=this.currentUser.full_name;
  // console.log("curent user",this.currentUser);
 
}
else{
  this.userLogedIn=false;
  this.currentUser=null;
  this.usre_id='';
  this.existImg=false;
  this.user_type=''
  this.user_img='';
}


}

  
  ngOnInit() {

    this.user.getLoggedInName.subscribe(name => this.changeName(name)); 
    this.checkOuth();

    if(localStorage.getItem('langflag') !=null)
    {
      if(localStorage.getItem('langflag') === 'English'){
        this.flag=this.flags[1];
        this.common._lang_code='en';
       console.log("this is main component and lang code en" );
      }
      else{
        this.flag=this.flags[0];
        this.common._lang_code='ar';
        console.log("this is main component and lang code ar" );
      }
    }
    else {
      this.flag = this.flags[0];
     this.common._lang_code='ar';
     console.log("this is main component and lang code ar by default" );
    }

    console.log("get currency curency code from localstorage",localStorage.getItem("currency_code"));
    this.getAllCurrencies();
   
  
    this.translate.use(this.flag.name);
    document.getElementsByTagName("html")[0].setAttribute("dir", this.flag.name === "العربية" ? 'rtl' : 'ltr');
    
    document.getElementsByTagName("html")[0].setAttribute("lang", this.flag.name);
   
    this.getAllLanguages();
    this.getAllCategories(this.common._lang_code);
  
  }

  userLogOut(){
      localStorage.removeItem("currentUserFront");
      localStorage.removeItem("tokenfront");
      this.userLogedIn=false;
      this.currentUser=null;
      this.usre_id='';
      this.user_type=''
      this.user_img='';
      this.user.logout();
      this.router.navigate(['/pages/my-account']);
  }

  public changeCurrency(currency) {
    this.currency = currency;
  }


  // public changeLang(flag){
  //   this.flag = flag;

  //   // document.getElementsByTagName("html")[0].setAttribute("dir", this.flag.name === "العربية" ? 'rtl' : 'ltr');
  //   // document.getElementsByTagName("html")[0].setAttribute("lang", this.flag.name);
  // }
  public changeLang(flag) {
    this.flag = flag;
    if (flag.name==='English') {
      this.common._lang_code='en';   
      console.log("language become english");
    }
    if(flag.name==='العربية'){
      this.common._lang_code='ar';
      console.log("language become arabic");
    }
    this.translate.use(this.flag.name);
    // console.log("emiiiiit",this.userLoggedIn)
    document.getElementsByTagName("html")[0].setAttribute("dir", this.flag.name === "العربية" ? 'rtl' : 'ltr');
    document.getElementsByTagName("html")[0].setAttribute("lang", this.flag.name);
    // this.userLoggedIn(flag);
    localStorage.setItem('langflag',flag.name);
    localStorage.setItem('lang_code',flag.code);
    // this.flag=localStorage.getItem('langflag')
    console.log("language become befor event ",this.common._lang_code);
    this.pubsub.$pub('categories',this.common._lang_code);
    var regp = /home\/product/gi;
    var contactpage = /home\/contact/gi;
    var regCP = /home\/category/gi;
    var pagereg = /page/gi;
    // var regSerch = /home\/search/gi;

    var url=this.router.url;
if (url.search(regp) == 1 ) {
  this.pubsub.$pub('productdetails',this.common._lang_code);
}
else if (url.search(regCP) == 1 ) { 
  this.pubsub.$pub('categoryProducts',this.common._lang_code);
 
}
else if (url.search(pagereg) == 1 ) { 
  this.pubsub.$pub('pagedetails',this.common._lang_code);
}
else if (url.search(contactpage) == 1 ) { 
  this.pubsub.$pub('contactuspage',this.common._lang_code);
}
else{
  this.pubsub.$pub('popularproducts',this.common._lang_code);
}



this.getAllCategories(this.common._lang_code)
 
}
}
