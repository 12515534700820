import { Component, OnInit,AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PubSubService } from 'angular7-pubsub';
import { CartService } from '../services/cart.service';
// import {HomeFourComponent } from 'src/app/components/shop/home-four';
// import {HomeFourComponent} from '';
import { Router, NavigationEnd } from '@angular/router';
import { from } from 'rxjs';
import { CommonService } from '../../../services/common.service';

import {  FormGroup, FormBuilder, Validators } from '@angular/forms';


declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-footer-two',
  templateUrl: './footer-two.component.html',
  styleUrls: ['./footer-two.component.sass']
})
export class FooterTwoComponent implements OnInit,AfterViewInit {

  subscriberForm: FormGroup;
  sociallinks=[];
  pagesarr =[]
  facebook='';
  twitter='';
  googleplus='';
  address='';
  address2='';
  addingStatus:string='';
  email='';
  linkedin='';
  wechat='';
  whatsapp='';
  lat='';
  lon='';
  phone='';
  hiddeninputlocation='';
  site_email;
  // lang_code='ar'
  lang_code=this.common._lang_code 
  constructor(public router: Router, private fb: FormBuilder, private cartService: CartService,public translate: TranslateService,private common:CommonService) {
    this.subscriberForm = fb.group({
      'email': [null, Validators.required],
      'hiddeninputlocation': null,
  });
   }


   addSubscriber(subscriber){

    var country=$("#hiddeninputlocation").attr('value');    
    if (this.email !== '' && this.common.validateEmail(this.email) && country !== '') {
              let body = {
                  "email": this.email,
                  "country":country
              };
              this.common.addSubscriber(body).subscribe((data) => {
                if (data.data==true) {
                  this.addingStatus='add'; 
                  this.email ='';
                }
                else if(data.data=='exist'){
                  this.addingStatus ='exist';
                }
                else if(data.data =='readd'){
                  this.addingStatus ='readd';
                  this.email ='';
                }
                else{
                  this.addingStatus='error';
                } 
      });
    }
    else{
      this.addingStatus='notemail';
    }
    
  }

  resetInput(){
    this.email='';
    this.addingStatus='';
  }

  getAllSocialMedia(){
    this.common.getAllSocialMedia().subscribe((data) => {
      if (data.data) {
        this.sociallinks = data.data;
        // console.log("sociallinks", this.sociallinks);
        this.site_email = data.data.site_email;
        this.phone= data.data.phone;
        this.facebook = data.data.facebook;
        this.twitter = data.data.twitter;
        this.googleplus = data.data.googleplus;
        this.linkedin = data.data.linkedin;
        this.address = data.data.address;
        this.wechat = data.data.wechat;
        this.whatsapp = data.data.whatsapp;
        this.lat = data.data.lat;
        this.lon = data.data.lon;
        this.address2 = data.data.address2;

      }
    });
  }

  getAllPages(lang_code){
    this.common.getAllPages(lang_code).subscribe((data) => {
      if (data.data) {
        this.pagesarr = data.data;
        // console.log("999pagesarr", this.pagesarr);
      }
    });
  }

  // getPopularProducts() {
  //   this.product.getPopularProducts().subscribe((data) => {
  //     if (data) {
  //       this.popularproducts = data.data;
  //       console.log("popularproducts", this.popularproducts);

  //     }
  //   });
  // }
  
  ngOnInit() {

  
    // console.log("this is footer component");
    this.getAllSocialMedia();
    this.getAllPages(this.lang_code);

  }

  ngAfterViewInit(){
    $(document).ready(function(){
          $.getJSON('https://www.geoplugin.net/json.gp?jsoncallback=?', function(data) {
            $("#hiddeninputlocation").attr('value',data.geoplugin_countryName);
          }); 
      });
  }
  

}
