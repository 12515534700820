import { Injectable, Inject ,EventEmitter,Output} from '@angular/core';

import { HttpClient, HttpInterceptor } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'

})

export class CommonService {
  //logo.png
  public _hostName = 'https://tmormadina.com/api/'
  // public _hostName = 'https://it-plus.co/tmormadina/api/'
 


  public_url='web-api/common.php?action='
  public _lang_code = 'ar';
  
  // @Output() public getLangweb: EventEmitter<any> = new EventEmitter();
  @Output() public getLangweb: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private titleService: Title, private meta: Meta,

   
    // @Inject('API_URL') 

    public translate: TranslateService,

  ) {



  }
  // http://192.168.1.120/china_price/api/web-api/common.php?action=getAllSlides
getAllSlides(){
  // console.log('Slides and')
  return this.http.get<any>(this._hostName + "web-api/common.php?action="+ 'getAllSlides' );
}
getAllCurrencies(){
  // console.log('get All Currencies common befor requist');
  return this.http.get<any>(this._hostName + "web-api/common.php?action="+ 'getAllCurrencies' );
}
// http://192.168.1.120/china_price/api/web-api/common.php?action=getAllSocialMedia

addSubscriber(data) {
  return this.http.post<any>(this._hostName + this.public_url + 'addSubscriber', data);
}
getAllSocialMedia(){

return this.http.get<any>(this._hostName + this.public_url + 'getAllSocialMedia')
}

range(start, end, step) {
  var range = [];
  var typeofStart = typeof start;
  var typeofEnd = typeof end;

  if (step === 0) {
    throw TypeError("Step cannot be zero.");
  }

  if (typeofStart == "undefined" || typeofEnd == "undefined") {
    throw TypeError("Must pass start and end arguments.");
  } else if (typeofStart != typeofEnd) {
    throw TypeError("Start and end arguments must be of same type.");
  }

  typeof step == "undefined" && (step = 1);

  if (end < start) {
    step = -step;
  }

  if (typeofStart == "number") {

    while (step > 0 ? end >= start : end <= start) {
      range.push(start);
      start += step;
    }

  } else if (typeofStart == "string") {

    if (start.length != 1 || end.length != 1) {
      throw TypeError("Only strings with one character are supported.");
    }

    start = start.charCodeAt(0);
    end = end.charCodeAt(0);

    while (step > 0 ? end >= start : end <= start) {
      range.push(String.fromCharCode(start));
      start += step;
    }

  } else {
    throw TypeError("Only string and number types are supported");
  }

  return range;

}



    
validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}




// http://192.168.1.120/china_price/api/web-api/common.php?action=getAllLanguages

getAllLanguages(){
return this.http.get<any>(this._hostName + this.public_url + 'getAllLanguages')
}

exchangcurrancies(yy,gg,){
  // console.log('exchangcurrancies in common service')
  return this.http.get<any>('http://free.currencyconverterapi.com/api/v3/convert?q='+yy+'_'+gg+'&compact=ultra&apiKey=f8ab48ff6f0260971974')
}
// http://192.168.1.120/china_price/api/web-api/pages.php?action=getAllPages&amp;lang_code=ar
getAllPages(lang_code){
  // console.log("pageeeeeeeeeeeees")
  return this.http.get<any>( this._hostName +'web-api/pages.php?action=getAllPages&lang_code='+lang_code)

}
seandOrderToAdmin(body){
  return this.http.post<any>(this._hostName + "web-api/common.php?action=seandOrderToAdmin", body);
}
seandOrderToAdminMessages(body){
  return this.http.post<any>(this._hostName + "web-api/common.php?action=seandOrderToAdminMessages", body);
}
seandOrderToUserMessages(body){
  return this.http.post<any>(this._hostName + "web-api/common.php?action=seandOrderToUserMessages", body);
}

prepareCheckout(body){
  return this.http.post<any>(this._hostName + "web-api/common.php?action=prepareCheckout", body);
}
prepareCheckoutMada(body){
  return this.http.post<any>(this._hostName + "web-api/common.php?action=prepareCheckoutMada", body);
}
getPaymentStatus(body){
  return this.http.post<any>(this._hostName + "web-api/common.php?action=getPaymentStatus", body);
}
getPaymentStatusMada(body){
  return this.http.post<any>(this._hostName + "web-api/common.php?action=getPaymentStatusMada", body);
}
// http://192.168.1.120/china_price/api/web-api/pages.php?action=getAllPages&amp;lang_code=ar&amp;page_id=16
getonepageDetails(lang_code,page_id){
  return this.http.get<any>(this._hostName+'/web-api/pages.php?action=getOnePageDetails&lang_code='+lang_code+'&page_id='+ page_id)
}
rejectEmailSubscriber(email){
  let body=JSON.stringify(email)
  return this.http.post<any>(this._hostName+'/web-api/pages.php?action=rejectEmailSubscriber',body)
}

limitString(txt: string, txtLength: number) {
  if (txt.length > txtLength) {
      const str = txt.substring(0, txtLength);
      return str + ' ...';
  } else {
      return txt;
  }
}



getSideAdvertisements() {
  return this.http.get<any>(this._hostName + "web-api/common.php?action=getSideAdvertisements");
}

getBottomAdvertisements() {
  return this.http.get<any>(this._hostName + "web-api/common.php?action=getBottomAdvertisements");
}

getCenterAdvertisements() {
  return this.http.get<any>(this._hostName + "web-api/common.php?action=getCenterAdvertisements");
}

getHeaderAdvertisements() {
  return this.http.get<any>(this._hostName +"web-api/common.php?action=getHeaderAdvertisements");
}


getAllShippingCompanies() {
  return this.http.get<any>(this._hostName +"web-api/common.php?action=getAllShippingCompanies");
}


}
 