import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { WishlistService } from 'src/app/components/shared/services/wishlist.service';
import { MatDialog } from '@angular/material';
// import { Router } from '@angular/router';
import { Product } from 'src/app/modals/product.model';
import { ProductDialogComponent } from '../../product-dialog/product-dialog.component';
import { ProductsService } from '../../../../../services/products.service';

import { CommonService } from '../../../../../services/common.service';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { PubSubService } from 'angular7-pubsub';
@Component({
  selector: 'app-product-tow',
  templateUrl: './product-tow.component.html',
  styleUrls: ['./product-tow.component.sass']
})
export class ProductTowComponent implements OnInit {

  catlogsearch: any = []
  urlimage
  q
  category_id='';
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Input() product: Product;

  constructor(private cartService: CartService, public productsService: ProductService, private wishlistService: WishlistService,
    private productstore: ProductsService, private common: CommonService
    , private dialog: MatDialog, private router: Router,private actRouter: ActivatedRoute,private pubsub: PubSubService,) {
    this.urlimage = this.common._hostName + 'uploads/products/';
    // console.log("product constactor");
    this.pubsub.$sub('categories').subscribe((from) => {
      this.getCatProductsDetails(from,this.category_id);
      // console.log("get cat products and language", from);
        
  });
  }



  getCatProductsDetails(lang_code, category_id) {
    this.productstore.getCatProductsDetails(lang_code, category_id).subscribe((data) => {
      if (data) {
        this.catlogsearch = data;
        // console.log("zzzzzzxxxxxxxx", this.catlogsearch)
      }
    });
   
  }



  ngOnInit() {   

    console.log("this is begin products componant");

    // console.log("this is products / product / price /product componant");
    this.actRouter.paramMap.subscribe((params: ParamMap) => {
      let category_id = params.get('id');
      this.productsService.getProducts(this.category_id)
        //  console.log("category_id", category_id)
        //  console.log("current url",this.router.url);
     
     
    })

    // console.log("this is end products componant");
  }
  // Add to cart
  public addToCart(product: Product, quantity: number = 1) {
      this.cartService.addToCart(product, quantity);
      // console.log(product, quantity);
    }

  // Add to wishlist
  public addToWishlist(product: Product) {
      this.wishlistService.addToWishlist(product);
    }

  // Add to compare
  public addToCompare(product: Product) {
      this.productsService.addToCompare(product);
    }


  public openProductDialog(product) {
      let dialogRef = this.dialog.open(ProductDialogComponent, {
        data: product,
        panelClass: 'product-dialog',
      });
      dialogRef.afterClosed().subscribe(product => {
        if (product) {
          this.router.navigate(['/products', product.id, product.name]);
        }
      });
    }

}
