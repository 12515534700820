import { Component, OnInit } from '@angular/core';
import { filter, retry,pairwise  } from 'rxjs/operators';

import { Router, NavigationEnd, Event, NavigationStart, NavigationError,RoutesRecognized  } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { CommonService } from '../../../services/common.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  typeinvaled: boolean = false;

  errorEmailErrorExsistMsg: boolean = false;
  activation_code_error: boolean = false;
  successEmail: boolean = false;
  lang_code: string = "ar";
  emailValue: string;
  errorMobileErrorExsistMsg: boolean = false;
  mobileValue: string;
  ufname: string = "";
  uemail: string = "";
  uphone: string = "";
  upassword: string = "";
  utype: string = "client";
  country_code: string = "+966";

  minute: number = 60;
  timerId: any;
  endtimedone: boolean = false;

  loginpassword = "";
  activation_code = "";
  public previousUrl = "";
  count: number = 0;
  public currentUrl = "";
  loginfaild: boolean = false;
  phoneNotCorrectNumber: boolean = false;
  Reset_password_done: boolean = false;
  userNotExist: boolean = false;

  formData: FormData = null;

  phonenum: any;
  existinguser: any;
  step1: any;
  step2: any;
  step3: any;
  step4: any;

  allCountries: any;

  public emailErrorExsistMsg: string = "";
  public emailErrorExsistMsgar: string = "هذا البريد الإلكترونى موجود من قبل";
  public emailErrorExsistMsgen: string = "This email already exists";
  public activationCodeMsg: string = "";
  public activationCodeMsgar: string =
    "هذا الكود غير صحيح من فضلك حاول مرة اخري";
  public activationCodeMsgen: string =
    "This code is incorrect. Please try again";
  public mobileErrorExsistMsg: string = "";
  public mobileErrorExsistMsgar: string = "هذا الموبايل موجود من قبل";
  public mobileErrorExsistMsgen: string = "This mobile already exists";
  public typeinvaledMsg: string = "";
  public typeinvaledMsgar: string = "الرجاء اختيار نوع";
  public typeinvaledMsgen: string = "Please Choose A Type";
  public loginfaildMsg: string = "";
  public loginfaildMsgar: string = "رقم الهاتف او كلمة المرور غير صحيحة";
  public loginfaildMsgen: string = "The Phone or password is incorrect";

  public countriesUrl = "assets/data/countries.json";

  constructor(
    private common: CommonService,
    private router: Router,
    private user: UserService,
    private httpClient: HttpClient
  ) {
    // GET ALL COUNTRIES
    this.countries().subscribe({
      next: (result) => {
        // console.log('all countries', result);
        this.allCountries = result;
      },
    });

    this.step1 = true;
    this.existinguser = false;
    // to get previous url
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects;
        this.currentUrl = events[1].urlAfterRedirects;
        localStorage.setItem("previousUrl", this.previousUrl);
        localStorage.setItem("currentUrl", this.currentUrl);
        console.log("set in local storage url in constractor");
        console.log(
          "previous url in constractor",
          localStorage.getItem("previousUrl")
        );
      });
  }

  public countries(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.countriesUrl);
  }

  GoStep2(phone) {
    this.phoneNotCorrectNumber = false;
    this.endtimedone = false;
    this.minute = 60;
    this.phonenum = phone;
    if (this.validatePhoneNumber(this.phonenum) == true) {
      if (this.phonenum != "" && this.phonenum.length > 8) {
        let datasend = {
          colomen: "mobile",
          value: this.country_code + "" + this.phonenum,
        };
        this.user.checkUserMobileExist(datasend).subscribe((data) => {
          if (data) {
            if (data.data == false && data.errors == "") {
              this.step1 = true;
              this.step2 = false;
              this.step3 = false;
              this.step4 = false;

              console.log("userNotExist");

              this.userNotExist = true;
              // this.existinguser = false;
              // this.step1 = false;
              // this.step2 = true;
              // this.step3 = false;
              // this.step4 = false;

              // this.timerId = setInterval(() => {
              //   this.minute=this.minute-1;
              //   console.log(" time is",this.minute);
              //     }, 1000);
              //     setTimeout(() => { clearInterval(this.timerId);
              //     console.log("end time");
              //     this.endtimedone=true;
              //     }, 60000);
            } else if (
              data.data == true &&
              data.errors == "this mobile exsist befor "
            ) {
              //  sent to him new password
              let datasend222 = {
                colomen: "mobile",
                value: this.country_code + "" + this.phonenum,
              };
              this.resetPassword(datasend222);
            }
          } else {
            console.log("something error happened");
          }
        });
      } else {
        this.phoneNotCorrectNumber = true;
      }
    } else {
      this.phoneNotCorrectNumber = true;
    }
  }

  resetPassword(data) {
    this.user.resetPassword(data).subscribe((data) => {
      if (data.data == true) {
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.existinguser = true;
        this.Reset_password_done = true;
      } else {
        console.log("something error happened");
      }
    });
  }

  onBlurEmail(event: any) {
    this.emailValue = event.target.value;
    var val = this.common.validateEmail(this.emailValue);
    if (val === true) {
      let data = {
        colomen: "email",
        value: this.emailValue,
      };

      this.user.chichEmailOrMobile(data).subscribe((data) => {
        if (data.data == true) {
          this.errorEmailErrorExsistMsg = true;
          this.emailErrorExsistMsg =
            this.lang_code == "ar"
              ? this.emailErrorExsistMsgar
              : this.emailErrorExsistMsgen;
        } else {
          console.log("the email not exist");
        }
      });
    }
  }
  onBlurMobile(event: any) {
    this.mobileValue = event.target.value;
    let data = {
      colomen: "mobile",
      value: this.country_code + this.mobileValue,
    };
    this.user.chichEmailOrMobile(data).subscribe((data) => {
      if (data.data == true) {
        // this.errorMobileErrorExsistMsg = true;
        // this.mobileErrorExsistMsg = this.lang_code == 'ar' ? this.mobileErrorExsistMsgar : this.mobileErrorExsistMsgen;
      } else {
        // console.log("the mobile not exist");
      }
    });
  }

  onKeyUpEmail(event: any) {
    console.log("onKeyUpEmail befor", this.successEmail);
    this.successEmail =
      !this.common.validateEmail(event.target.value) &&
      event.target.value !== ""
        ? true
        : false;
    console.log("onKeyUpEmail after", this.successEmail);
    this.errorEmailErrorExsistMsg = false;
  }
  onKeyUpMobile(event: any) {
    console.log("onKeyUpMobile", this.uphone);
    if (this.validatePhoneNumber(this.uphone) == true) {
      console.log("correct phone ");
      this.phoneNotCorrectNumber = false;
      this.errorMobileErrorExsistMsg = false;
    } else {
      console.log("not correct phone ");
      return;
    }
  }

  validatePhoneNumber(input_str) {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{3,4})$/;
    return re.test(input_str);
  }

  changeType() {
    console.log("this.utype", this.utype);
    if (this.utype == "") {
      this.typeinvaled = true;
      this.typeinvaledMsg =
        this.lang_code == "ar" ? this.typeinvaledMsgar : this.typeinvaledMsgen;
    } else {
      this.typeinvaled = false;
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    if (localStorage.getItem("langflag") != null) {
      if (localStorage.getItem("langflag") === "English") {
        this.common._lang_code = "en";
        console.log("this is my  acount component and lang code en");
      } else {
        this.common._lang_code = "ar";
        console.log("this is my  acount  and lang code ar");
      }
    } else {
      this.common._lang_code = "ar";
      console.log("this is my  acount and lang code ar by default");
    }

    this.lang_code = this.common._lang_code;
    console.log(
      "previous localstorage oninit url",
      localStorage.getItem("previousUrl")
    );
    console.log(
      "current localstorage oninit url",
      localStorage.getItem("currentUrl")
    );
  }
}
