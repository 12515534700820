import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { UserService} from '../../../services/user.service';
import { PubSubService } from 'angular7-pubsub';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass']
})
export class ContactComponent implements OnInit {
  uemail:string= '';
  umsg:string= '';
  usubject:string= '';
  uname:string= '';
  sociallinks=[];
  pagesarr =[]
  facebook;
  twitter;
  googleplus;
  address;
  phone;
  site_email;
  // lang_code="ar"
  lang_code=this.common._lang_code 
 
  namecorecr: boolean = false;
  msgcorecr: boolean = false;
  namerrors: string = '';
  msgerrors: string = '';
  emailcorecr: boolean = false;
  emailrrors: string = '';
  subjecterrors: string = '';
  subjectcorecr: boolean = false;
  emailsendcorrect: boolean = false;
  successEmail: boolean = false;

  constructor(private common:CommonService,private pubsub:PubSubService ,private user:UserService ) { 
    this.pubsub.$sub('contactuspage').subscribe((from) => {
      // this.sidenavOpened = false;
      console.log("Contact Us Component constactor");
      this.ngOnInit();
  });
  }
  onKeyUpEmail(event: any) {
    console.log("onKeyUpEmail befor", this.successEmail);
    this.successEmail = !this.common.validateEmail(event.target.value) && event.target.value !== '' ? true : false;
    console.log("onKeyUpEmail after", this.successEmail);
    
  }
  resetinputs(){
    this.uemail='';
    this.umsg='';
    this.usubject='';
    this.uname='';
  }
  sendMailContactUs() {
    
    let body = {
      "email": this.uemail,
      "name": this.uname,
      "subject": this.usubject,
      "msg": this.umsg,
      "lang_code": this.lang_code
    }
    this.successEmail = !this.common.validateEmail(this.uemail) && this.uemail !== '' ? true : false;
    if (this.uname == '') {
      this.namerrors = "required namee";
      this.namecorecr = true;
      console.log(this.namerrors)
    }
    if (this.uname == '') {
      this.msgerrors = "required msg";
      this.msgcorecr = true;
      console.log(this.msgerrors)
    }
    if (this.uemail == '') {
      this.emailrrors = "required email";
      this.emailcorecr = true;
      console.log(this.emailrrors)
    }
    if (this.usubject== '') {
      this.subjecterrors = "required address";
      this.subjectcorecr = true;
      console.log(this.subjecterrors)
    }
   
    if(this.subjectcorecr === true || this.emailcorecr === true|| this.namecorecr === true || this.msgcorecr === true|| this.successEmail === true){
      console.log("error data")    
      return ;
    }else{
        this.user.sendMailContactUs(body).subscribe((data) => {
          if (data) {
            this.emailsendcorrect=true;
            document.querySelector('body').scrollTo(0,0)
            document.body.scrollTop = 0;
            this.resetinputs();
          }
          else {
            this.emailsendcorrect=false;
          }
        }); 
      }
}
  getAllSocialMedia(){
    this.common.getAllSocialMedia().subscribe((data) => {
      if (data.data) {
        this.sociallinks = data.data;
        //  console.log("sociallinks", this.sociallinks);
        this.site_email = data.data.site_email;
        this.phone= data.data.phone;
        this.address = data.data.address;

      }
    });
  }

  ngOnInit() {


    if(localStorage.getItem('langflag') !=null)
    {
      if(localStorage.getItem('langflag') === 'English'){
        this.common._lang_code='en';
       console.log("this is about us component and lang code en" );
      }
      else{
        this.common._lang_code='ar';
        console.log("this is about us and lang code ar" );
      }
    }
    else {
     this.common._lang_code='ar';
     console.log("this is about us and lang code ar by default" );
    }

    this.lang_code=this.common._lang_code;
   
    document.getElementsByClassName("title")[0].setAttribute("style", this.lang_code == "ar" ? 'text-align: right;' : 'text-align: left;');
    document.getElementsByClassName("title")[1].setAttribute("style", this.lang_code == "ar" ? 'text-align: right;' : 'text-align: left;');
    this.getAllSocialMedia();
   
  }

}
