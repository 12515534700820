import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import {CommonService} from '../../../services/common.service';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-main-carousel',
  templateUrl: './main-carousel.component.html',
  styleUrls: ['./main-carousel.component.sass']
})
export class MainCarouselComponent implements OnInit {
  materialphotos=[];
  baseuslimage;
  loading:boolean=true;
  loadingImgUrl:string='';
  @Input('slides') slides: Array<any> = [];

  public config: SwiperConfigInterface = {};

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

  constructor( private Common:CommonService, private router:Router ) {
   this.baseuslimage=this.Common._hostName +'uploads/slider/';
   this.loadingImgUrl=this.Common._hostName +'uploads/loading.gif';
   }

   
  getAllSlides(){
    this.loading=true;
    this.Common.getAllSlides().subscribe((data) => {
      if (data) {
        this.materialphotos = data.data;
        // console.log("slidesbb",this.materialphotos);
        this.loading=false;
      }
      else{
        this.materialphotos=[];
        this.loading=false;
      }
    });
  }
  gotoslidedetails(url){
    this.router.navigate(url); 
   }
  ngOnInit() {
    this.getAllSlides();
   }

  ngAfterViewInit(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      // grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      },
      speed: 500,
      effect: "slide"
    }
  }




}
