import { Injectable,Output,EventEmitter } from '@angular/core';
import {CommonService} from './common.service';
import { HttpClient, HttpInterceptor } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  
  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
 

  private _url: string = this.common._hostName + 'web-api/users.php?action=';

  constructor(private common:CommonService, private http:HttpClient) { 
    
  }

 

  // http://192.168.1.120/china_price/api/web-api/users.php?action=sendMailContactUs
  sendMailContactUs(data){
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'sendMailContactUs', body);
  }
  chichEmailOrMobile(data){
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'chichEmailOrMobile', body);
  }
  checkUserMobileExist(data){
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'checkUserMobileExist', body);
  }
  resetPassword(data){
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'resetPassword', body);
  }
  checkVerificationCode(data){
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'checkVerificationCode', body);
  }
  getMyOrders(data){
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getMyOrders', body);
  }
  getMyOrdersCount(data){
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getMyOrdersCount', body);
  }

  getOneOrderDetails(data){
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getOneOrderDetails', body);
  }
  userSignUp(data){
    return this.http.post<any>(this._url + 'userSignUp', data);
  }
  userLogin(data){
    return this.http.post<any>(this._url + 'userLogin', data);
  }

  userLoggedIn(name){
    this.getLoggedInName.emit(name);
  }

  logout(): void {
    this.getLoggedInName.emit('');
  }

  getToken() {
    return localStorage.getItem("tokenfront");
  }
  getCurrentUser() {
    return localStorage.getItem("currentUserFront");
  }


  



}
