import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Observable, of } from 'rxjs';
import { CartItem } from 'src/app/modals/cart-item';
import { CartService } from '../../shared/services/cart.service';
import { UserService } from '../../../services/user.service';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { PubSubService } from 'angular7-pubsub';

@Component({
  selector: 'app-check-payemnt-status',
  templateUrl: './check-payemnt-status.component.html',
  styleUrls: ['./check-payemnt-status.component.sass']
})
export class CheckPaymentStatus implements OnInit {
  public datasendcorrect: boolean = false;

  page = []
  donePages: boolean = false;
  emailResult: any[] = []
  done:boolean=false;
  email;
  public lang_code=''; 
  public userLogin: boolean = false;
  usre_id: string = '';
  transactionId:any;
  payemntData:any;
  order_pending: any;
  
  amount: number;
  delivey_price: number=0;
  user_name: string = '';
  oaddress: string = '';
  opostcode: string = '';
  pay_method:string='';
  home_description: string = '';
  ocontent: string = ''; // extra data
  shipping_company: string = 'smsa'; // extra data

  ostate: string = '';
  otown: string = '';
  currentUser: any;
  public cartItems: Observable<CartItem[]> = of([]);
  public shoppingCartItems: CartItem[] = [];

  public buyProductsToadmin: any[] = [];

  constructor(private cartService: CartService,private common: CommonService, public user: UserService,private pubsub:PubSubService ,private actRouter: ActivatedRoute,private router:Router) { 
    this.lang_code=this.common._lang_code;
  }

  ngOnInit() {

    this.cartItems = this.cartService.getItems();
    this.cartItems.subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    this.getTotal().subscribe(amount => this.amount = amount);
    if (this.user.getToken() !== null) {
      this.currentUser = JSON.parse(this.user.getCurrentUser());
      this.usre_id = this.currentUser.id;
      this.userLogin = true;
    }

    if(localStorage.getItem("order_pending_information") != null ){
      this.order_pending=JSON.parse(localStorage.getItem("order_pending_information"));
      this.user_name=this.order_pending.user_name;
      this.oaddress=this.order_pending.address;
      this.opostcode=this.order_pending.post_code;
      this.delivey_price=this.order_pending.delivey_price > 0 ?this.order_pending.delivey_price:0;
      this.ostate=this.order_pending.country;
      this.otown=this.order_pending.city;
      this.pay_method=this.order_pending.pay_method;
    
      this.home_description=this.order_pending.home_description;
      this.ocontent=this.order_pending.additional_inf;
      this.shipping_company=this.order_pending.shipping_company;
     

}
  
    this.actRouter.queryParams.subscribe(params => {

      this.transactionId = params.id;

      if (this.transactionId !== null || this.transactionId !== '') {
        
        if( this.pay_method == 'mada'){
          this.getPaymentStatusMada();
        }else{
          this.getPaymentStatus();
        }
        

          }
        else 
          {
            this.router.navigate(['/home/cart']);
          }
  });

  }

  
  public getTotal(): Observable<number> {
    return this.cartService.getTotalAmount();
  }

  getPaymentStatus(){

      let body = {
        "user_id": this.usre_id,
        "id":  this.transactionId
      }
      this.common.getPaymentStatus(body).subscribe((data) => {
        if (data) {
          if(data.data){
           
            this.payemntData= JSON.parse(data.data);
            console.log("type",typeof(this.payemntData));
            console.log("return from payment method",this.payemntData);
            var code=this.payemntData['result']['code'];
            if(code == '000.000.000'){
              this.sendOrderToAdmin();
            }
            else{
              this.router.navigate(['/home/cart/fail']);
            }

          }else{
            this.router.navigate(['/home/cart/fail']);
          }
        }
        else{
          this.router.navigate(['/home/cart/fail']);
        }
      });
    }


    getPaymentStatusMada(){

      let body = {
        "user_id": this.usre_id,
        "id":  this.transactionId
      }
      this.common.getPaymentStatusMada(body).subscribe((data) => {
        if (data) {
          if(data.data){
           
            this.payemntData= JSON.parse(data.data);
            console.log("type",typeof(this.payemntData));
            console.log("return from payment method",this.payemntData);
            var code=this.payemntData['result']['code'];
            if(code == '000.000.000'){
              
             this.sendOrderToAdmin();

            }
            else{
              this.router.navigate(['/home/cart/fail']);
            }

          }else{
            this.router.navigate(['/home/cart/fail']);
          }
        }
        else{
          this.router.navigate(['/home/cart/fail']);
        }
      });
    }

    sendOrderToAdmin(){
      var user = {
        "user_id": this.usre_id,
        "token": this.user.getToken(),
        "user_name": this.user_name,
        "address": this.oaddress,
        "post_code": this.opostcode,
        "country": this.ostate,
        "city": this.otown,
        "home_description": this.home_description,
        "additional_inf": this.ocontent,
        "shipping_company": this.shipping_company
      }
      if (this.shoppingCartItems.length > 0) {
        this.buyProductsToadmin = [];
        for (let i = 0; i < this.shoppingCartItems.length; i++) {
          let productToAdmin = {
            "product_id": this.shoppingCartItems[i].product.id,
            "quantity": this.shoppingCartItems[i].quantity,
            "name": this.shoppingCartItems[i].product.name,
            "price": this.shoppingCartItems[i].product.price,
            "total": this.shoppingCartItems[i].product.price * this.shoppingCartItems[i].quantity + '',
          };
          this.buyProductsToadmin.push(productToAdmin);
        }
        this.getTotal().subscribe(amount => this.amount = amount);
        let body = {
          "user": user,
          "products": this.buyProductsToadmin,
          "lang_code": this.common._lang_code,
          "general_total": Number(Number(this.amount)+Number(this.delivey_price)),
          "delivey_price": this.delivey_price,
          "currency": localStorage.getItem("currency_code"),
          "pay_method": this.pay_method,
        }

        this.common.seandOrderToAdmin(body).subscribe((data) => {
          if (data.data) {
            this.datasendcorrect = true;
            this.resetInputs();
            this.removeAllFromCard();
            localStorage.removeItem("order_pending_information");
            this.seandOrderToAdminMessages(data.data,this.usre_id);
            this.seandOrderToUserMessages(data.data,this.usre_id);
          }
          else {
            this.buyProductsToadmin = [];
          }

        });
      }
    }
    
  seandOrderToAdminMessages(order_id,user_id) {
    let body = {
      "user_id": user_id,
      "order_id": order_id,
      "token": this.user.getToken(),
    }
    this.common.seandOrderToAdminMessages(body).subscribe((data) => {
      if (data.data) {
      console.log("seand OrderToAdminMessages success")
      }
      else {
        console.log("seand OrderToAdminMessages error")
      }
    });
  } 


   seandOrderToUserMessages(order_id,user_id) {
    let body = {
      "user_id": user_id,
      "order_id": order_id,
      "token": this.user.getToken(),
    }

    this.common.seandOrderToUserMessages(body).subscribe((data) => {
      if (data.data) {
      console.log("seand seandOrderToUserMessages success")
      }
      else {
        console.log("seand seandOrderToUserMessages error")
      }
    });
   
  }
    resetInputs() {

      this.oaddress = "";
      this.opostcode = "";
      this.ostate = "";
      this.otown = "";
      this.ocontent = "";
      this.shipping_company = "smsa";
      this.buyProductsToadmin = [];
      this.pay_method='cash';
      this.home_description='';
    }
    removeAllFromCard() {

      for (let i = 0; i < this.shoppingCartItems.length; i + 2) {
        this.cartService.removeFromCartAll(this.shoppingCartItems[i])
      }
      this.buyProductsToadmin = [];
  
    }
}
