import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


declare var jquery: any;
declare var $: any;

import { CommonService } from './common.service';
import { JsonPipe } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  lang_code:string='';
  private _url: string = this.commService._hostName + 'web-api/categories.php?action=';
  private _url2: string = this.commService._hostName + 'web-api/products.php?action='
  constructor(public http: HttpClient, public commService: CommonService) {
    if(localStorage.getItem('langflag') !=null)
    {
      this.commService._lang_code=localStorage.getItem('lang_code');
    }
    else{
      this.commService._lang_code='ar';
    }
    this.lang_code=this.commService._lang_code;
   }


  getAllCategories(lang_code) {
  
    return this.http.get<any>(this._url + 'getAllCategories&lang_code=' + lang_code);
  }
  // : http://192.168.1.120/china_price/api/web-api/products.php?action=getPopularProducts&amp;lang_code=ar


  // http://192.168.1.120/china_price/api/web-api/products.php?action=getAllProducts&amp;lang_code=ar
  getAllProducts(lang_code) {
    return this.http.get<any>(this._url2 + 'getAllProducts&&lang_code=' + lang_code);
  }

  getPopularProducts(lang_code) {
   
    return this.http.get<any>(this._url2 + 'getPopularProducts&lang_code=' +lang_code);
  }
  getIP() {
   console.log("get data inserves");
   
    // var ttttt=this.http.get<any>('https://www.cloudflare.com/cdn-cgi/trace');
    // var gggg=JSON.stringify(ttttt);
    // console.log('ttttt',gggg);
  }
  getFeaturedProducts(lang_code) {
  
    return this.http.get<any>(this._url2 + 'getFeaturedProducts&lang_code=' +lang_code);
  }
  getCategoryproductsInHome(lang_code) {
  
    return this.http.get<any>(this._url2 + 'getCategoryproductsInHome&lang_code=' +lang_code);
  }
  getLatestproducts(lang_code) {
    return this.http.get<any>(this._url2 + 'getLatestProducts&lang_code=' + lang_code);

  }

  getCatProductsDetails(lang_code ,category_id) {
    console.log('category_id77777',lang_code)
    return this.http.get<any>(this._url2+ 'getCatProductsDetails&lang_code=' + lang_code + '&category_id=' + category_id)

  
  }

  getProductDetails(lang_code,id){
    console.log('get Product Details')
    return this.http.get<any>(this._url2 + 'getProductDetails&lang_code=' + lang_code + '&id=' + id)
 
  } 
  getProductRatings(id){
    return this.http.get<any>(this._url2 + 'getProductRatings&id=' + id)
  }

  getProductPricesCats(id){
    console.log('get Product Prices Cats')
    return this.http.get<any>(this._url2 + 'getProductPricesCats&id=' + id)
 
  }

  
  getIncreaseProductViews(id){
    let product_id=JSON.stringify(id)
    return this.http.post<any>(this._url2 + "getIncreaseProductViews", product_id);
  
  }
   sendProductRating(data){
    let body=JSON.stringify(data)
    return this.http.post<any>(this._url2 + "sendProductRating", body);
  
  }
  getSearchResult(lang_code ,q) {
    console.log('get Search Result66666 in service');
    return this.http.get<any>(this._url2 + 'getSearchResult&lang_code=' + lang_code + '&q=' + q);
  }

}
