import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { UserService } from './app/services/user.service'

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

    constructor(private injector: Injector) { }

    intercept(req, next) {

        let authService = this.injector.get(UserService);

        let tokenizedReq = req.clone({
            setHeaders: { Authorization:`${authService.getToken()}`},
            // setHeaders: { Authorization: `${authService.getToken()}` }
        })
        return next.handle(tokenizedReq);

    }

}
