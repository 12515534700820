import { Component, OnInit, ViewChild } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { CartItem } from 'src/app/modals/cart-item';
import { CartService } from '../services/cart.service';
import { SidebarMenuService } from '../sidebar/sidebar-menu.service';
import { TranslateService } from '@ngx-translate/core';
import { PubSubService } from 'angular7-pubsub';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import {ProductsService} from "../../../services/products.service";
// import {CartItem} from "../../modals/cart-item";
import { CommonService } from "../../../services/common.service";

// import { Router, NavigationEnd } from '@angular/router';
// import { SidebarMenuService } from '../shared/sidebar/sidebar-menu.service';
import { SidenavMenu } from '../sidebar/sidebar-menu.model';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
// import {TranslateHttpLoader} from '@ngx-translate/http-loader'; 


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  public sidenavMenuItems: Array<any>;

  public currencies = ['USD', 'EUR'];
  public currency: any;
  public flags = [
    { name: 'English', image: 'assets/images/flags/gb.svg' },
    // { name:'German', image: 'assets/images/flags/de.svg' },
    // { name:'French', image: 'assets/images/flags/fr.svg' },
    // { name:'Russian', image: 'assets/images/flags/ru.svg' },
    { name: 'Arabic', image: 'assets/images/flags/sa.svg' }
  ]
  public flag: any;
  q:string=''
  products: Product[];
  // lang_code='ar';
  lang_code='';
  indexProduct: number;
  shoppingCartItems: CartItem[] = [];
  langs;
  langchosen;
  langes =[]
  SSresult:any=[]
  imgurl=this.common._hostName+'uploads/languages/';
  myArraycategory=[]
  name
  procat=[]
  phone='';
  wechat='';
  whatsapp='';
  sociallinks:[]=[];
  public advertiseImg='';
  public url_target='';
  public advertiseImgUrl='';
  public imagAdvertisePath='';
  constructor(public router: Router, private cartService: CartService, public translate: TranslateService,private actRouter: ActivatedRoute,
    private pubsub: PubSubService, public sidenavMenuService: SidebarMenuService, private common: CommonService,private productstore:ProductsService) {
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    // this.pubsub.$sub('categories').subscribe((from) => {
    //   // this.sidenavOpened = false;
    //   this.productService.fetproducts(from);
    //   console.log("00RR", from);

    // });
    this.imagAdvertisePath=this.common._hostName+"uploads/advertisements/";
    this.lang_code=this.common._lang_code;
  }

  getAllLanguages() {
    console.log("headr component");
    this.common.getAllLanguages().subscribe((data) => {
      if (data.data) {
        this.langes = data.data;
        // console.log("langes", this.langes);
      }
    });
  }


  getAllCategories(lang_code) {
   
    this.productstore.getAllCategories(lang_code).subscribe((data) => {
      if (data) {
        console.log("getAllCategories metode");

        // this.materialphotos = data.data;
        this.myArraycategory = data.data;
        // this.subcategory=data.data[1];
        // this.subcategory=data.;
        // console.log("NNNNNNNNNNNNNNNNN6666",this.myArraycategory);
        
        // console.log("NNN6666",this.subcategory);

        // console.log("NNN6666",this.subcategory);
        // this.dropdown.push(this.subcategory);
        // console.log(this.dropdown,'wwwwwwwwwwww')

        // // let  dropdown = {
        // //   // id: number;
        // //   // name: string;
         
        // //   // sub_categories: [];
        // //   // img: string;
        // // };
      
        // let array = [];
        // for(let key in this.subcategory){
        //  if(this.subcategory.hasOwnProperty(key)){
        //    array.push(this.subcategory[key]);
        //  }
        // }
        // console.log(array,'kkk');
        // console.log(array[(2)],'ggggg');
      }
      // let arr = [];
      // Object.keys(data.data).map(function (key) {
      //   arr.push({ [key]:data.data[key] })
      //   // this.myArraycategory=arr;
      //   return arr;

      // });
      // console.log('Object=',data.data)
      // console.log('Array=', arr)
    });
  }



  getAllSocialMedia(){
    this.common.getAllSocialMedia().subscribe((data) => {
      if (data.data) {
        this.sociallinks = data.data;
        this.phone= data.data.phone;
        this.wechat = data.data.wechat;
        this.whatsapp = data.data.whatsapp;
       
        // console.log("88888sociallinks=",this.sociallinks);

     

      }
    });
  }

  
  getAllHeaderAdvertisements() {
    
    this.common.getHeaderAdvertisements().subscribe((data) => {
      if (data) {

        let nandNum= Math.floor(Math.random() * data.length) ;
        this.advertiseImg=this.imagAdvertisePath + data[nandNum].img;
        this.advertiseImgUrl=data[nandNum].url;
        this.url_target=data[nandNum].url_target;
      
      }else
      {
        this.advertiseImg='';
      }
    });
  }
  
  goTosearchDetails() {
   console.log("qqqqqqqqqq=",this.q);
    this.router.navigate(['/home/search/'+this.q]);
    // [routerLink]="['/home/search',q]" >
  }
  getSearchResult(lang_code,q) {
    this.productstore.getSearchResult(lang_code,q).subscribe((data) => {
      if (data) {
        this.SSresult = data;
        console.log("SSresultmmmmmmmmmmmm",data);
        

      }
    });
  }
  ngOnInit() {

    
this.getAllHeaderAdvertisements();
    this.lang_code=this.common._lang_code;
   
    this.currency = this.currencies[0];
    // this.flag = this.flags[0];
    this.getAllLanguages();
    this.flag = this.langes;
    // this.exchangcurrancies();
    // this.getSearchResult('ar',this.q);
    // this.actRouter.paramMap.subscribe((params: ParamMap) => {

    //   let q = params.get('q');
    //   this.getSearchResult(this.lang_code,this.q);
    //   //  this.catlogsearch:product
    //   console.log("xxxxxxx",this.SSresult)});
      this.getAllSocialMedia();
  }

  public changeCurrency(currency) {
    this.currency = currency;
  }
  public changeLang(flag) {
    this.flag = flag;
    console.log('lang=', flag.name);
    // this.events.publish('lang:Changed', (lang));
    this.flag = flag;
    // this.dataServices.localStorage_().set('lang', this.flag);
    // this.pubsub.$pub('lang', this.flag);
    // Change Global Lang to Selected one
    // this.translate.use(this.lang);
    this.pubsub.$pub('lang', this.flag.name);
    // Change Global Lang to Selected one
    this.translate.use(this.flag.name);



    document.getElementsByTagName("html")[0].setAttribute("dir", this.flag.name === "Arabic" ? 'rtl' : 'ltr');
    document.getElementsByTagName("html")[0].setAttribute("lang", this.flag.name);
    // if (this.router.url.split('/').includes('')) {
    this.ngOnInit();
    // } else {
    //   this.router.navigate(['']);
    // }
    localStorage.setItem('lang', flag.name);
    // localStorage_().set('lang', this.flag);
    // this.langchosen = JSON.parse(this.langs);
    console.log('lang', "fffff", flag.name)
    console.log('nnnnnnnnnn', localStorage.getItem('lang'))


  }

  // exchangcurrancies(){
  //   this.common.exchangcurrancies().subscribe((data) => {
  //     if (data) {
  //       this.currencies= data;
  //       console.log("exchangcurranciesqqqqqqqqqqqqqqqqqqqqqqqqqq", this.currencies);
        

  //     }
  //   });
  // }




}
