import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { HomeComponent } from './components/shop/home/home.component';
import { DemoComponent } from './components/demo/demo.component';

import { WishlistComponent } from './components/pages/wishlist/wishlist.component';
import { MyAccountComponent } from './components/pages/my-account/my-account.component';
import { ResetPasswordComponent } from './components/pages/reset-password/reset-password.component';
import { MyOrdersComponent } from './components/pages/my-orders/my-orders.component';
import { HomeFourComponent } from '../app/components/shop/home-four/home-four.component';
import { ProductDetailsComponent } from './components/shop/products/product-details/product-details.component';
import { ProductLeftSidebarComponent } from './components/shop/products/product-left-sidebar/product-left-sidebar.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CartComponent} from './components/pages/cart/cart.component';
import { CheckoutComponent} from './components/pages/checkout/checkout.component';

import { CategoriesComponent } from './components/shop/widgets/categories/categories.component';
import { CategoriesSectionComponent } from './components/shared/categories-section/categories-section.component';
import { BlogDetailsComponent } from './components/blog/blog-details/blog-details.component';
import { BlogColumnComponent } from './components/blog/blog-column/blog-column.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { RejectEmailComponent } from './components/pages/reject-email-subscriber/reject-email.component';
import { CheckPaymentStatus } from './components/pages/check-payemnt-status/check-payemnt-status.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrderSuccessComponent } from './components/pages/order-success/order-success.component';
import { OrderSuccessGuard } from './guards/order-success.guard';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeFourComponent
  },
  { path: 'one', component: HomeFourComponent },
  //{ path: '', component: MainComponent },
  // { path: 'two', component: HomeTwoComponent },
  // { path: 'three', component: HomeThreeComponent },
  { path: 'home', component: HomeFourComponent },
  // { path: 'five', component: HomeFiveComponent },
  // { path: 'home/products/:id', component: ProductLeftSidebarComponent },
  // ['/home/category']
  { path: 'home/category/:id', component: ProductLeftSidebarComponent },
  { path: 'user-profile/:id', component: ProductLeftSidebarComponent },
  // { path: 'home/category/:id', component: BlogColumnComponent},
  
  // '/home/Search/q'
  // { path: 'home/Search', component: ProductLeftSidebarComponent },
  { path: 'pages/wishlist', component: WishlistComponent },
  { path: 'pages/my-account', component: MyAccountComponent },
  { path: 'pages/reset-password', component: ResetPasswordComponent },
  { path: 'pages/my-orders', component: MyOrdersComponent },
  { path: 'home/contact', component: ContactComponent },
  { path: 'home/product/:id', component: ProductDetailsComponent },
  { path: 'home/blog-details', component: BlogDetailsComponent},
 
  { path: 'home/search/:q', component:CategoriesSectionComponent },
  // { path: 'home/Search/:name', component:CategoriesSectionComponent },
  { path:'page',component:AboutUsComponent},
  { path:'rejectemail/:email',component:RejectEmailComponent},
  { path:'checkpaymentstatus',component:CheckPaymentStatus},
  // 

  // { path: 'about', component: AboutUsComponent },
  { path: 'home/cart', component: CartComponent },
  { path: 'home/cart/:message', component: CartComponent },
  { path: 'home/checkout', component: CheckoutComponent },
  {
    path: "",
    canActivate: [OrderSuccessGuard],
    children: [
      { path: 'order-success', component: OrderSuccessComponent,}
    ]
  },
  // { path: 'faq', component: FaqComponent },
  // { path: 'contact', component: ContactComponent },
 
  // { path: 'compare', component: CompareComponent },
  // { path: 'my-account', component: MyAccountComponent },
  // { path: 'error', component: ErrorPageComponent },

  // 
  // {
  //   path: '',
  //   component: MainComponent,
    // children: [
    //   {
    //     path: 'home',
    //     loadChildren: './components/shop/shop.module#ShopModule'
    //   },
    //   {
    //     path: 'pages',
    //     loadChildren: './components/pages/pages.module#PagesModule'
    //   },
    //   {
    //     path: 'blog',
    //     loadChildren: './components/blog/blog.module#BlogModule'
    //   }
    // ]

  // },

  // { path: 'blog-list', component: BlogListComponent},
  // { path: 'home/blog-column', component: BlogColumnComponent},

  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
