import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../../../services/products.service';
import { CommonService } from '../../../services/common.service'
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { PubSubService } from 'angular7-pubsub';
import { Product, ColorFilter } from 'src/app/modals/product.model';

@Component({
  selector: 'app-categories-section',
  templateUrl: './categories-section.component.html',
  styleUrls: ['./categories-section.component.sass']
})
export class CategoriesSectionComponent implements OnInit {
  SSresult = [];
  urlimage;
  public animation: any;   // Animation
  qe: string = '';
  productcat = [];
  public allItems: Product[] = [];
  public products: Product[] = [];
  lang_code:string = ''
  namesearch = ''
  product = [];
  public tags: any[] = [];
  public colors: any[] = [];
  dataDone: boolean = false;
  loading:boolean=true;
  loadingImgUrl:string='';
  public items: Product[] = [];

  public sortByOrder: string = '';   // sorting
  public page: any;
  public tagsFilters: any[] = [];
  public viewType: string = 'grid';
  public viewCol: number = 25;
  public colorFilters: ColorFilter[] = [];


  constructor(private common: CommonService, private productstore: ProductsService, private router: Router, private actRouter: ActivatedRoute,private pubsub: PubSubService,) {
    this.urlimage = this.common._hostName + 'uploads/products/';
    this.loadingImgUrl=this.common._hostName +'uploads/loading.gif';
    this.lang_code=this.common._lang_code;
  }





  getSearchResult(lang_code, q) {
    this.loading=true;
    this.productstore.getSearchResult(lang_code, q).subscribe((data) => {
      if (data) {
        this.product = data;
        this.allItems=data;
        this.loading=false;
        if(this.allItems.length>0){
          this.dataDone=true;
          this.products = data.slice(0.8);
        }
        else{
          this.loading=false;
        this.dataDone=false;
        }
       
        console.log("Search result", this.product);
      }else{
        this.loading=false;
        this.dataDone=false;

      }
     
    
    });
  }

  

  // Get current product tags
  public getTags(products) {
    var uniqueBrands = []
    var itemBrand = Array();
    products.map((product, index) => {
      if (product.tags) {
        product.tags.map((tag) => {
          const index = uniqueBrands.indexOf(tag);
          if (index === -1) uniqueBrands.push(tag);
        })
      }
    });
    for (var i = 0; i < uniqueBrands.length; i++) {
      itemBrand.push({ brand: uniqueBrands[i] })
    }
    this.tags = itemBrand
  }

  // Get current product colors
  public getColors(products) {
    var uniqueColors = []
    var itemColor = Array();
    products.map((product, index) => {
      if (product.colors) {
        product.colors.map((color) => {
          const index = uniqueColors.indexOf(color);
          if (index === -1) uniqueColors.push(color);
        })
      }
    });
    for (var i = 0; i < uniqueColors.length; i++) {
      itemColor.push({ color: uniqueColors[i] })
    }
    this.colors = itemColor
  }


 

  ngOnInit() {
    this.loading=true;
    console.log("this Categories Section Component ngoninit");
    if(localStorage.getItem('langflag') !=null)
    {
      this.common._lang_code=localStorage.getItem('lang_code');
    }
    else{
      this.common._lang_code='ar';
    }
    this.lang_code=this.common._lang_code;
    this.actRouter.paramMap.subscribe((params: ParamMap) => {
      this.qe = params.get('q');
      console.log("55", this.qe)
      this.getSearchResult(this.lang_code, this.qe);
    })
 
  }


  

  public changeViewType(viewType, viewCol) {
    this.viewType = viewType;
    this.viewCol = viewCol;
  }
  // Animation Effect fadeIn
  public fadeIn() {
    this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
    this.animation = 'fadeOut';
  }

  // Update tags filter
  public updateTagFilters(tags: any[]) {
    this.tagsFilters = tags;
    this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }



  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
    this.sortByOrder = val;
    this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }

  // Initialize filetr Items
  public filterItems(): Product[] {
    return this.items.filter((item: Product) => {
      const Colors: boolean = this.colorFilters.reduce((prev, curr) => { // Match Color
        if (item.colors) {
          if (item.colors.includes(curr.color)) {
            return prev && true;
          }
        }
      }, true);
      const Tags: boolean = this.tagsFilters.reduce((prev, curr) => { // Match Tags
        if (item.tags) {
          if (item.tags.includes(curr)) {
            return prev && true;
          }
        }
      }, true);
      return Colors && Tags; // return true
    });

  }

  public onPageChanged(event) {
    this.page = event;
    this.allItems;
    window.scrollTo(0, 0);
  }


  // Update price filter
  //   public updatePriceFilters(price: any) {
  //     let items: any[] = [];
  //     this.products.filter((item: Product) => {
  //         if (item.price >= price[0] && item.price <= price[1] )  {
  //            items.push(item); // push in array
  //         }
  //     });
  //     this.items = items;
  // }


  // Update price filter
  public updatePriceFilters(price: any) {
    console.log(price);
    console.log(this.products);


    this.allItems = this.products.filter((item: Product) => {
      return item.price >= price.priceFrom && item.price <= price.priceTo
    });
    console.log(this.products);

  }

  onBrendsChanged(newBrend) {
    console.log(newBrend);
    this.allItems = newBrend === 'all' ? this.products : this.products.filter(

      item => item.brand === newBrend
    )
    console.log(this.allItems);


  }



}
