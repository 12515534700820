import { Injectable } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CartItem } from 'src/app/modals/cart-item';
import { ProductsService } from 'src/app/services/products.service';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subscriber, interval } from 'rxjs';

// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("cartItem")) || [];

@Injectable({
  providedIn: 'root'
})
export class CartService {

  addingmessage='';
  addingmessagear=' .  تم الاضافة الى عربة التسوق بنجاح  ';
  addingmessageen='Successfully added to the cart . ';
  lang=localStorage.getItem('lang_code');

  public productPriceCat:any[]=[];
  // Array
  public cartItems  :  BehaviorSubject<CartItem[]> = new BehaviorSubject([]);
  public observer   :  Subscriber<{}>;

  public orderSuccess: boolean;

  constructor(public snackBar: MatSnackBar ,private productstore: ProductsService) {
    this.cartItems.subscribe(
      products => products = products
    );
   }

    // Get Products
  public getItems(): Observable<CartItem[]> {
    const itemsStream = new Observable(observer => {
      observer.next(products);
      observer.complete();
    });
    return <Observable<CartItem[]>>itemsStream;
  }

  getProductPricesCats( product,quantity) {
    this.productstore.getProductPricesCats(product.id)
      .subscribe(
        (data) => {
          if(this.lang=='en'){
            this.addingmessage=this.addingmessageen;
          }else{
            this.addingmessage=this.addingmessagear;
          }
          if(data['data']){
            this.productPriceCat=data['data'];
            console.log("data return prices categories",this.productPriceCat);

            let message, status;
            var item: CartItem | boolean = false;
            // If Products exist
            let hasItem = products.find((items, index) => {
              if(items.product.id == product.id) {
                let qty = products[index].quantity + quantity;
                let stock = this.calculateStockCounts(products[index], quantity);
              
                if(qty > this.productPriceCat[this.productPriceCat.length - 1].to){
                  products[index].product['price']=this.productPriceCat[this.productPriceCat.length - 1].price;
                }else{
                  for(let i=0;i<this.productPriceCat.length;i++){
                    if(qty >=this.productPriceCat[i].from && qty <=this.productPriceCat[i].to){
                      products[index].product['price']=this.productPriceCat[i].price;
                    }
                 }
                }
                if(qty != 0 && stock) {
                  products[index]['quantity'] = qty;
                  console.log("productsproductsproductsproducts1111",products)
                  message = this.addingmessage;
                  status = 'success';
                  this.snackBar.open(message, '✓', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
                }
                return true;
              }
            } );
       
            // If Products does not exist (Add New Products)
            if(!hasItem) {
             item = { product: product, quantity: quantity };
             if(quantity > this.productPriceCat[this.productPriceCat.length - 1].to){
              product['price']=this.productPriceCat[this.productPriceCat.length - 1].price;
            }
             else{
              for(let i=0;i<this.productPriceCat.length;i++){
                  if(quantity >=this.productPriceCat[i].from && quantity <=this.productPriceCat[i].to){
                    product['price']=this.productPriceCat[i].price;
                    console.log("product price first add ", product['price'])
                  }
              }
             }
            
             products.push(item);
             message = this.addingmessage;
             status = 'success';
             this.snackBar.open(message, '✓', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
         }
       
            localStorage.setItem("cartItem", JSON.stringify(products));
            return item;
          }else{

            let message, status;
            var item: CartItem | boolean = false;
            // If Products exist
            let hasItem = products.find((items, index) => {
              if(items.product.id == product.id) {
                let qty = products[index].quantity + quantity;
                let stock = this.calculateStockCounts(products[index], quantity);
                if(qty != 0 && stock) {
                  products[index]['quantity'] = qty;
                  console.log("productsproductsproductsproducts22222",products)
                  message = this.addingmessage;
                  status = 'success';
                  this.snackBar.open(message, '✓', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
                }
                return true;
              }
            } );
       
            // If Products does not exist (Add New Products)
            if(!hasItem) {
             item = { product: product, quantity: quantity };
             products.push(item);
             message = this.addingmessage;
             status = 'success';
             this.snackBar.open(message, '✓', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
         }
       
            localStorage.setItem("cartItem", JSON.stringify(products));
            return item;
          }
          
        }
      )
  }

  getProductPricesCatsOnUpdate( product,quantity) {
    
    this.productstore.getProductPricesCats(product.id)
      .subscribe(
        (data) => {
          if(data['data']){
            this.productPriceCat=data['data'];
            console.log("data return prices categories",this.productPriceCat);

            return products.find((items, index) => {
              if(items.product.id == product.id) {
                let qty = products[index].quantity + quantity;
                let stock = this.calculateStockCounts(products[index], quantity);
                products[index].product['price']=this.productPriceCat[0] ?this.productPriceCat[0].price_one_piece:products[index].product['price'];
                if(qty == 1){
                  products[index].product['price']=this.productPriceCat[0] ?this.productPriceCat[0].price_one_piece:products[index].product['price'];
                }else{
                 
                    for(let i=0;i<this.productPriceCat.length;i++){
                      if(qty >=this.productPriceCat[i].from && qty <=this.productPriceCat[i].to){
                        products[index].product['price']=this.productPriceCat[i].price;
                      }
                  }
                }
                
                if (qty != 0 && stock)
                  products[index]['quantity'] = qty;
                  console.log("productsproductsproductsproducts",products)
                localStorage.setItem("cartItem", JSON.stringify(products));
                return true;
              }
            });
          }else{
            return products.find((items, index) => {
              if(items.product.id == product.id) {
                let qty = products[index].quantity + quantity;
                let stock = this.calculateStockCounts(products[index], quantity);
                if (qty != 0 && stock)
               
                  products[index]['quantity'] = qty;
                  products[index].product['price']=this.productPriceCat[0] ?this.productPriceCat[0].price_one_piece:products[index].product['price'];
                localStorage.setItem("cartItem", JSON.stringify(products));
                return true;
              }
            });
          }
          
        }
      )
  }


   // Add to cart
   public addToCart(product: Product, quantity: number) {
    this.getProductPricesCats(product,quantity);
   }

// Calculate Product stock Counts
public calculateStockCounts(product: CartItem, quantity): CartItem | Boolean {
  let message, status;
  let qty   = product.quantity + quantity;
  let stock = product.product.stock;
  if(stock < qty) {
    // this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.');
    this.snackBar.open('You can not choose more items than available. In stock ' + stock + ' items.', 'x', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    return false
  }
  return true
}





// Removed in cart
public removeFromCart(item: CartItem) {
  if (item === undefined) return false;
    const index = products.indexOf(item);
    products.splice(index, 1);
    location.reload();
    localStorage.setItem("cartItem", JSON.stringify(products));
}

public removeFromCartAll(item: CartItem) {
  if (item === undefined) return false;
    const index = products.indexOf(item);
    products.splice(index, 1);
    localStorage.setItem("cartItem", JSON.stringify(products));
}

// Total amount
public getTotalAmount(): Observable<number> {
  return this.cartItems.pipe(map((product: CartItem[]) => {
    return products.reduce((prev, curr: CartItem) => {
      return prev + curr.product.price * curr.quantity;
    }, 0);
  }));
}

// Update Cart Value
public updateCartQuantity(product: Product, quantity: number) {
 
 this.getProductPricesCatsOnUpdate(product,quantity);
 
}


}
