import { Component, OnInit, Renderer2 } from '@angular/core';
import { ProductService } from '../services/product.service';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PubSubService } from 'angular7-pubsub';
import { ProductsService } from '../../../services/products.service';
export  interface subcategory {
  id: number;
  name: string;
  sub_categories: [];
  img: string;
}
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass']
})

export class MenuComponent implements OnInit {

  myArraycategory = [];
  subcategory ;
  sub_categories;
  subcat:boolean=false;
  dropdown: subcategory[] = [];
  lang_code:string='';
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger;

  // changelang: Subscription;
  
  constructor(private product: ProductService, private common: CommonService, private productstore: ProductsService,
    public translate: TranslateService,
    public router: Router,
    private ren: Renderer2,
    private pubsub: PubSubService) {
      this.lang_code=this.common._lang_code;
     
      this.pubsub.$sub('categories').subscribe((from) => {
        // this.sidenavOpened = false;
        console.log("menu component constactor");
        this.getAllCategories(from);
    });

  }

  getAllCategories(lang_code) {
   
    this.productstore.getAllCategories(lang_code).subscribe((data) => {
      if (data) {
        this.myArraycategory = data.data;
        // console.log( "================> ", this.myArraycategory);
      }

    });
  }



  ngOnInit() {
    console.log("menu component");
    if(localStorage.getItem('langflag') !=null)
    {
      if(localStorage.getItem('langflag') === 'English'){
        this.common._lang_code='en';
       console.log("this is menu component and lang code en" );
      }
      else{
        this.common._lang_code='ar';
        console.log("this is menu component and lang code ar" );
      }
     
    }
    else {
     
     this.common._lang_code='ar';
     console.log("this is menu component and lang code ar by default" );
    }

    this.lang_code=this.common._lang_code;
    this.getAllCategories(this.lang_code);
  }
  openMegaMenu() {
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
      if (el.children.length > 0) {
        if (el.children[0].classList.contains('mega-menu')) {
          el.classList.add('mega-menu-pane');
        }
      }
    });
  }

}
