import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../shared/services/product.service';
import { Product } from 'src/app/modals/product.model';
import { TranslateService } from '@ngx-translate/core';
import { PubSubService } from 'angular7-pubsub';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
  products: Product[];
  public banners = [];
  public slides = [
    { title: 'Huge sale', subtitle: 'Up to 70%', image: 'assets/images/carousel/banner1.jpg' },
    { title: 'Biggest discount', subtitle: 'Check the promotion', image: 'assets/images/carousel/banner2.jpg' },
    { title: 'Biggest sale', subtitle: 'Dont miss it', image: 'assets/images/carousel/banner3.jpg' },
    { title: 'Our best products', subtitle: 'Special selection', image: 'assets/images/carousel/banner4.jpg' },
    { title: 'Massive sale', subtitle: 'Only for today', image: 'assets/images/carousel/banner5.jpg' }
  ];
  category_id
  constructor(private productService: ProductService, public translate: TranslateService,
    private pubsub: PubSubService) { }

  ngOnInit() {


    
    this.productService.getBanners()
      .subscribe(
        data => this.banners = data

      );

    this.productService.getProducts(this.category_id)
      .subscribe(
        (product: Product[]) => {
          this.products = product
        }
      )
      ;
      
    // this.productService.popularproducts()
    // .subscribe(
    //   (product:popularproducts[]) => {
    //     this.products = popularproductst
    //   }
    // )

  }


  // public changeLang(flag){
  //   this.flag = flag;
  //   console.log('lang=',flag.name);
  //   // this.events.publish('lang:Changed', (lang));
  //   this.flag = flag;
  //   // this.dataServices.localStorage_().set('lang', this.flag);
  //   // this.pubsub.$pub('lang', this.flag);
  //   // Change Global Lang to Selected one
  //   // this.translate.use(this.lang);
  //   this.pubsub.$pub('lang', this.flag.name);
  //   // Change Global Lang to Selected one
  //   this.translate.use(this.flag.name);



  //   document.getElementsByTagName("html")[0].setAttribute("dir", this.flag === "Arabic" ? 'rtl' : 'ltr');
  //   document.getElementsByTagName("html")[0].setAttribute("lang", this.flag);
  //   // if (this.router.url.split('/').includes('')) {
  //   this.ngOnInit();
  //   // } else {
  //   //   this.router.navigate(['']);
  //   // }
  //   localStorage.setItem('lang',flag.name);
  //   // localStorage_().set('lang', this.flag);
  //   // this.langchosen = JSON.parse(this.langs);
  //   console.log('lang',"fffff",flag.name)
  //   console.log('nnnnnnnnnn',localStorage.getItem('lang'))

  // }



}
