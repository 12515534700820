import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import {  SwiperDirective } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ProductDialogComponent } from '../../products/product-dialog/product-dialog.component';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { WishlistService } from 'src/app/components/shared/services/wishlist.service';
import {ProductsService} from '../../../../services/products.service';
import {CommonService} from '../../../../services/common.service';
import {MycurrencyPipe} from '../../../main/custom.currencypipe';

@Component({
  selector: 'app-product-carousel',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.sass']
})
export class ProductCarouselComponent implements OnInit {
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Input('product') product: any[] = [];
  public config: SwiperConfigInterface = {};

  currentDate
  // popularproducts:[];
  urlimage;
  public currencies = ['SAR', 'USD', 'CNY', 'EGP'];
  public currency: any;
  isSomeClass
  constructor(private dialog: MatDialog, private Common:CommonService,
    // private MycurrencyPipe:MycurrencyPipe,
  private productstore:ProductsService ,private router: Router, private cartService: CartService, private productService: ProductService, private wishlistService: WishlistService) { 
   this.urlimage=this.Common._hostName+'uploads/products/';
   this.isSomeClass=true;
   
  }


  // getPopularProducts(){
  //   this.productstore.getPopularProducts().subscribe((data) => {
  //     if (data) {
  //       this.popularproducts = data.data;
  //       console.log("popularproducts",this.popularproducts);

  //     }
  //   });
  // }
  // exchangcurrancies(currency) {
  //   this.currency = currency;
  //   console.log("ooooooooooooo product carosel",this.currency);

  //   // console.log(sddd," sdgsdfsdffdf ");
  //   this.Common.exchangcurrancies("CNY",this.currency).subscribe((data) => {
      
  //     if (data) {
  //       // this.jhiyuigy = data;
  //       // this.jhiyuigy.base="CNY"
  //       console.log("tttttttttttqqqqqqqqq ",data);




  //     }
  //   });
  // }

  ngOnInit() {
    // this.currency = this.currencies[0];
    // console.log("bbbbbb",  this.currency )
    // this.exchangcurrancies(this.currency);
    this.currency = this.currencies[0];
    // this.getPopularProducts();
    // this.mypipe.transform(this.currency)
  }
  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 4,
      loopedSlides: 1,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
        reverseDirection: true
      },
      // preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 2
        },
        768: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },


      }
    }
  }


  public openProductDialog(product){
    let dialogRef = this.dialog.open(ProductDialogComponent, {
        data: product,
        panelClass: 'product-dialog',
    });
    dialogRef.afterClosed().subscribe(product => {
      if(product){
        this.router.navigate(['/products', product.id, product.name]);
      }
    });
  }

   // Add to cart
   public addToCart(product: Product,  quantity: number = 1) {
    this.cartService.addToCart(product,quantity);
    console.log(product, quantity);
  }

   // Add to wishlist
   public addToWishlist(product: Product) {
    this.wishlistService.addToWishlist(product);
 }

    // Add to compare
    public addToCompare(product: Product) {
      this.productService.addToCompare(product);
   }
}
