import { Component, OnInit } from '@angular/core';
import { CartService } from '../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';

import { CartItem } from 'src/app/modals/cart-item';
import { ProductService } from '../../shared/services/product.service';
import { UserService} from '../../../services/user.service';
import { CommonService } from "../../../services/common.service";
// import { userInfo } from 'os';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.sass']
})
export class CheckoutComponent implements OnInit {

  public cartItems: Observable<CartItem[]> = of([]);
  public buyProducts: CartItem[] = [];
 
  public buyProductsToadmin: any[] = [];

  amount: number;
  delivey_price: number=0;
 
  companyname:string='';
  oaddress:string='';
 
  ostate:string='';
  otown:string='';
  opostcode:string='';
 
  generl_total:number=0;
  ocontent:string=''; // extra data
  shipping_company:string=''; 
  currentUser:any;
  user_img='';
  existImg:boolean=false;
  userLogedIn:boolean=false;
  saveOrderProgress:boolean=false;
  user_full_name='';
  usre_id:any;
  user_type='';

  
  datasend:boolean=false;
  payments: string[] = ['Create an Account?', 'Flat Rate'];
  paymantWay: string[] = ['Direct Bank Transfer', 'PayPal'];
  

  constructor(private cartService: CartService,private router: Router,public user:UserService, private common: CommonService ,public productService: ProductService) { }

 
checkOuth(){
  if(this.user.getToken() !== null){
    this.userLogedIn=true;
    this.currentUser=JSON.parse(this.user.getCurrentUser());
    this.usre_id=this.currentUser.id;
    this.user_type=this.currentUser.user_type;
    this.user_img=this.currentUser.img;
    this.existImg=this.user_img !==''?true:false;
    this.user_full_name=this.currentUser.full_name;
    // console.log("curent user",this.currentUser);
  }
  else{
    this.userLogedIn=false;
    this.currentUser=null;
    this.usre_id='';
    this.existImg=false;
    this.user_type=''
    this.user_img='';
    this.router.navigate(['/pages/my-account']);
  }
  
  
  }

  ngOnInit() {
   
    window.scrollTo(0,0);
    this.checkOuth();
    this.cartItems = this.cartService.getItems();
    this.cartItems.subscribe(products => this.buyProducts = products);
    this.getTotal().subscribe(amount => this.amount = amount);
    console.log("buy Products",this.buyProducts);
  }


  seandOrderToAdmin(){

    this.saveOrderProgress=true;

    if(this.ostate==""||this.opostcode==""|| this.oaddress==""|| this.shipping_company==""){
      this.saveOrderProgress=false;
      return;
    }
    else{
      var user={
        "user_id":this.usre_id,
        "token":this.user.getToken(),
        "company_name":this.companyname,
        "address":this.oaddress,
        "post_code":this.opostcode,
        "country":this.ostate,
        "city":this.otown,
        "additional_inf":this.ocontent,
        "shipping_company":this.shipping_company
      }
      if(this.buyProducts.length >0){
        for(let i=0;i<this.buyProducts.length;i++){
          let productToAdmin={"product_id":this.buyProducts[i].product.id,
          "quantity":this.buyProducts[i].quantity,
          "name":this.buyProducts[i].product.name,
          "price":this.buyProducts[i].product.price,
          "total":this.buyProducts[i].product.price*this.buyProducts[i].quantity +'',
        };
          this.buyProductsToadmin.push(productToAdmin);
        }
        this.getTotal().subscribe(amount => this.amount = amount);
        let body={
          "user":user,
          "products":this.buyProductsToadmin,
          "lang_code":this.common._lang_code,
          "general_total":Number(Number(this.amount)+Number(this.delivey_price)) ,
          "delivey_price":this.delivey_price ,
          "currency" : localStorage.getItem("currency_code"),
        }
        this.saveOrderProgress=true;
        this.common.seandOrderToAdmin(body).subscribe((data) => {
          if (data.data) {
            this.saveOrderProgress=false;
              this.datasend=true;
              this.resetInputs();
              this.removeAllFromCard();
              this.seandOrderToAdminMessages(data.data,this.usre_id);
            
          }
          else{
            this.buyProductsToadmin=[];
            this.saveOrderProgress=false;
          }
          this.saveOrderProgress=false;
  
      });
      }
      }
  }
  

  seandOrderToAdminMessages(order_id,user_id) {
    let body = {
      "user_id": user_id,
      "order_id": order_id,
      "token": this.user.getToken(),
    }

    this.common.seandOrderToAdminMessages(body).subscribe((data) => {
      if (data.data) {
      console.log("seand OrderToAdminMessages success")
      }
      else {
        console.log("seand OrderToAdminMessages error")
      }

    });
   
  }
  resetInputs(){
    
    this.companyname="";
    this.oaddress="";
    this.opostcode="";
    this.ostate="";
    this.otown="";
    this.ocontent="";
    this.shipping_company="";
    this.buyProductsToadmin=[];
    this.saveOrderProgress=false;
  }

  removeAllFromCard(){
   
    for(let i=0;i<this.buyProducts.length;i+2){
      this.cartService.removeFromCartAll(this.buyProducts[i])
    }
    this.buyProductsToadmin=[];
   
  }
  


  public getTotal(): Observable<number> {
    return this.cartService.getTotalAmount();
    }

}
