import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ProductZoomComponent } from './product-zoom/product-zoom.component';
import { HttpClient } from '@angular/common/http';
import { ProductsService } from '../../../../services/products.service'
import { CommonService } from '../../../../services/common.service'
import { UserService } from '../../../../services/user.service'
import { from } from 'rxjs';
import { PubSubService } from 'angular7-pubsub';
import { Product } from 'src/app/modals/product.model';


declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.sass'],

})
export class ProductDetailsComponent implements OnInit {

  public config: SwiperConfigInterface = {};

  @ViewChild('zoomViewer', { static: true }) zoomViewer;
  @ViewChild(SwiperDirective, { static: true }) directiveRef: SwiperDirective;

  public products: Product[] = [];
  private starfillcount:number=0;
  public image: any;
  public product_rate_id: string='';
  public user_comment: string='';
  public currentUser: any;
  public usre_id: string;
  public user_img: string='';
  public total_product_rate='';
  public total_rate_percentage:number=0;
  public ratings: any[]=[];
  public ratingsCount:number=0;

  existImg:boolean=false;
  userLogedIn:boolean=false;
  user_full_name='';

  public zoomImage: any;
  popularproducts: any[];
  public counter: number = 1;
  public price_one_piece: string ='';
  public product_description: string ='';
  public productPriceCat:any[]=[];
  public generalDescriptionProductForms :any[]=[];
  public userImgPath:string='';
  public defaultImgPath:string='';
  selected = 0;
  currentRate = 4;
  index: number;
  id: number;
  category_id
  // ProductDetails
  urlimage
 
  lang_code = this.common._lang_code
  constructor(private route: ActivatedRoute, public productsService: ProductService, private product: ProductsService, public dialog: MatDialog, private router: Router, private cartService: CartService,
    private productstore: ProductsService, private http: HttpClient, private common: CommonService, public user: UserService,private pubsub:PubSubService) {

    this.urlimage = this.common._hostName + 'uploads/products/';
    this.defaultImgPath =  this.common._hostName + 'uploads/no_image.jpg';
    this.userImgPath =  this.common._hostName + 'uploads/users/';
  this.pubsub.$sub('productdetails').subscribe((from) => {
    // this.sidenavOpened = false;
    // console.log("home four component constactor");
    this.ngOnInit();
});

this.pubsub.$sub('popularproducts').subscribe((from) => {
  // console.log("home four component constactor");
  this.getPopularProducts(from);

});
    
  }

  

checkOuth(){
      if(this.user.getToken() !== null){
        this.userLogedIn=true;
        this.currentUser=JSON.parse(this.user.getCurrentUser());
        this.usre_id=this.currentUser.id;
        this.user_img=this.currentUser.img;
        this.existImg=this.user_img !='' && this.user_img != null?true:false;
        this.user_full_name=this.currentUser.full_name;
        // console.log("curent user",this.currentUser);
      }
      else{
        this.userLogedIn=false;
        this.currentUser=null;
        this.usre_id='';
        this.existImg=false;
        this.user_img='';
      }
  }

  getProductDetails(lang_code, product_id) {
    
    this.generalDescriptionProductForms=[];
    this.productPriceCat=[];
    this.productstore.getProductDetails(lang_code, product_id)
      .subscribe(
        (data) => {
          this.product = data['data'];
          this.price_one_piece=data['data']['price'];
          this.product_description=data['data']['description'];
          this.productPriceCat=data['productPriceCat'];
          this.generalDescriptionProductForms=data['generalDescriptionProductForms'];
          console.log("product  this.product",this.product_description);
        }

      )
  }
  getIncreaseProductViews(product_id) {
    this.productstore.getIncreaseProductViews(product_id)
      .subscribe(
        (data) => {
          // console.log("get Increase Product Views successfully");
        }

      )
  } 
  gotologin(){
    localStorage.setItem("previousUrl", '/home/product/'+this.product_rate_id);
    this.router.navigate(['/pages/my-account']);
  }
  sendProductRating() {
    
    if(this.user_comment !== ''){
      if(this.userLogedIn ===true){
        let data={
          "user_id":this.usre_id,
          "product_id":this.product_rate_id,
          "comment":this.user_comment,
          "rate":this.starfillcount,
        }
        this.productstore.sendProductRating(data)
          .subscribe(
            (data) => {
              if(data.data ==true){
                this.starfillcount=0;
                this.user_comment='';
                this.getProductRatings(this.product_rate_id);
                $(".img").attr('src','assets/images/empty_star.png'); 
              }
            }
          );
      }else{
          localStorage.setItem("previousUrl", '/home/product/'+this.product_rate_id);
          this.router.navigate(['/pages/my-account']);
        }
    }
    else{
      return ;
    }
   
  }
  
  imageStarClick(event: any,id) { 
    
    this.starfillcount=0;
    let src=event.target.src;
    let re=/empty_star.png/gi;
    var empty=false;
    if (src.search(re) == -1 ) { 
      empty=false;
    } 
    else { 
      empty=true;
    } 
   
    for(let i=1;i<6;i++){
      if(i<id){
        this.starfillcount=this.starfillcount+1;
        $("#"+i).attr('src','assets/images/Filled_star.png');
      }
      else if(i>id){
        $("#"+i).attr('src','assets/images/empty_star.png'); 
      }
      else if(i==id && empty==true){
        this.starfillcount=this.starfillcount+1;
        $("#"+i).attr('src','assets/images/Filled_star.png');
      }
      else if(i==id && empty==false){
        $("#"+i).attr('src','assets/images/empty_star.png');
      }
      
    }
  }
  getProductRatings(product_id) {
    
    this.generalDescriptionProductForms=[];
    this.productPriceCat=[];
    this.productstore.getProductRatings(product_id)
      .subscribe(
        (data) => {
          if(data.data){
            // console.log("ratings",data.data);
            this.total_product_rate=Number(data.data.total_rate).toFixed(1);
            this.total_rate_percentage=Number(this.total_product_rate) * 20;
            this.ratings=data.data.ratings;
            this.ratingsCount=this.ratings.length;
          }
        
        }
      )
  }


  ngOnInit() {

    this.checkOuth();
    if(localStorage.getItem('langflag') !=null)
    {
      if(localStorage.getItem('langflag') === 'English'){
       
        this.common._lang_code='en';
      //  console.log("this is and lang code en" );
      }
      else{
       
        this.common._lang_code='ar';
        // console.log("this is and lang code ar" );
      }
    }
    else {
     this.common._lang_code='ar';
    //  console.log("this is and lang code ar by default" );
    }


    this.lang_code=this.common._lang_code;

    // console.log("this is products / product details componant")
  
   
    // this.getRelatedProducts();
    // this.productstore.getProductDetails('ar',this.id );
    //192.168.1.120/china_price/api/web-api/products.php?action=getProductDetails&&lang_code=ar&&product_id=15
    this.route.paramMap.subscribe((params: ParamMap) => {

      let product_id = params.get('id');
      this.product_rate_id=params.get('id');
      this.getProductDetails(this.lang_code, product_id);
      this.getProductRatings(product_id);
      this.getIncreaseProductViews(product_id)
    })

    this.getPopularProducts(this.lang_code);
  }


  ngAfterViewInit() {
    this.config = {
      observer: false,
      slidesPerView: 4,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: false,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 2
        },
        600: {
          slidesPerView: 3,
        }
      }
    }
  }

  public selectImage(image) {
    this.image = image.medium;
    this.zoomImage = image.big;
  }




  public increment() {
    this.counter =Number(this.counter)+1;
    if(this.productPriceCat.length >0){
      for(let i=0;i<this.productPriceCat.length;i++){
        if(this.counter >=this.productPriceCat[i].from && this.counter <=this.productPriceCat[i].to){
          this.product['price']=this.productPriceCat[i].price;
        }
    }
  }
  }

  public decrement() {
    if (this.counter > 1) {
      this.counter -= 1;
      this.product['price']=this.price_one_piece;
      if(this.counter == 1){
        this.product['price']=this.price_one_piece;
      }else{
        if(this.productPriceCat.length >0){
          for(let i=0;i<this.productPriceCat.length;i++){
            if(this.counter >=this.productPriceCat[i].from && this.counter <=this.productPriceCat[i].to){
              this.product['price']=this.productPriceCat[i].price;
            }
        }
      }
      }

    }
  }

  // getRelatedProducts() {
  //   this.productsService.getProducts(this.category_id)
  //     .subscribe(
  //       (product: Product[]) => {
  //         this.products = product
  //       });
  // }

  getPopularProducts(lang_code) {
    this.product.getPopularProducts(lang_code).subscribe((data) => {
      if (data) {
        this.popularproducts = data.data;
        // console.log("getPopularProducts",this.popularproducts);
      }
    });
  }
  

  // Add to cart
  public addToCart(product: Product, quantity) {
    quantity=this.counter;
    if (quantity == 0) return false;
    this.cartService.addToCart(product, parseInt(quantity));
  }

  // Add to cart
  //    public buyNow(product: Product, quantity) {
  //     if (quantity > 0)
  //       this.cartService.addToCart(product,parseInt(quantity));
  //       this.router.navigate(['/pages/checkout']);
  //  }



  public onMouseMove(e) {
    if (window.innerWidth >= 1280) {
      var image, offsetX, offsetY, x, y, zoomer;
      image = e.currentTarget;
      offsetX = e.offsetX;
      offsetY = e.offsetY;
      x = offsetX / image.offsetWidth * 100;
      y = offsetY / image.offsetHeight * 100;
      zoomer = this.zoomViewer.nativeElement.children[0];
      if (zoomer) {
        zoomer.style.backgroundPosition = x + '% ' + y + '%';
        zoomer.style.display = "block";
        zoomer.style.height = image.height + 'px';
        zoomer.style.width = image.width + 'px';
      }
    }
  }

  public onMouseLeave(event) {
    this.zoomViewer.nativeElement.children[0].style.display = "none";
  }

  public openZoomViewer() {
    this.dialog.open(ProductZoomComponent, {
      data: this.zoomImage,
      panelClass: 'zoom-dialog'
    });
  }



}


