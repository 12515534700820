import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { TranslateService } from '@ngx-translate/core';
// import { CartService } from '../../../shared/services/cart.service';
// import {commonser}
// import { Router, NavigationEnd } from '@angular/router';
// import {ProductsService} from '../../../services/products.service';
import {CommonService} from 'src/app/services/common.service';
import {ProductsService} from 'src/app/services/products.service';
// import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
// import {ProductsService} from '../../../services/products.service'
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-blog-column',
  templateUrl: './blog-column.component.html',
  styleUrls: ['./blog-column.component.sass']
})
export class BlogColumnComponent implements OnInit {
  catlogsearch=[]
  constructor(private productService: ProductService,public translate: TranslateService,
    private Common:CommonService,private productstore:ProductsService,private router: Router, private actRouter: ActivatedRoute,
)  { }
getCatProductsDetails(lang_code, category_id) {
  this.productstore.getCatProductsDetails('ar', category_id).subscribe((data) => {
    if (data) {
      this.catlogsearch = data;
      // product=this.catlogsearch
      console.log("catcategory_id",this.catlogsearch);

      //  this.catlogsearch=Product;

      //  this.Product:this.catlogsearch;
      console.log("5555666666",data)
    }
  });
  // catlogsearch:ProductsComponent
}

  ngOnInit() {
  
    // this.productService.popproducts()
    this.actRouter.paramMap.subscribe((params: ParamMap) => {

      let category_id = params.get('id');
      // this.getCatProductsDetails('ar', category_id);
      this.productstore.getCatProductsDetails('ar',category_id)
      // category_id
      //  this.catlogsearch:product
      console.log("z444",category_id)
      // category_id
        //  console.log("category_id", category_id)

      // console.log("4444")
    })
 
  }

}
