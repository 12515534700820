import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { PubSubService } from 'angular7-pubsub';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.sass']
})
export class AboutUsComponent implements OnInit {
  page = []
  donePages: boolean = false;
  pageResult: any[] = []
  done:boolean=false;
  page_id;
  public lang_code=''; 
  
  constructor(private common: CommonService,private pubsub:PubSubService ,private actRouter: ActivatedRoute,private router:Router) { 

    this.lang_code=this.common._lang_code;
    
    this.pubsub.$sub('pagedetails').subscribe((from) => {
      // this.sidenavOpened = false;
      console.log("About Us Component constactor");
      this.getPageDetails(from,this.page_id);
  });
  }


  getPageDetails(lang_code,page_id) {

    this.common.getonepageDetails(lang_code,page_id).subscribe((data) => {
      if (data.data) {
        this.pageResult=data.data
        console.log("pageResult",this.pageResult)
        this.done = true;
      }
    });
  }


  ngOnInit() {

    if(localStorage.getItem('langflag') !=null)
    {
      if(localStorage.getItem('langflag') === 'English'){
        this.common._lang_code='en';
       console.log("this is about us component and lang code en" );
      }
      else{
        this.common._lang_code='ar';
        console.log("this is about us and lang code ar" );
      }
    }
    else {
     this.common._lang_code='ar';
     console.log("this is about us and lang code ar by default" );
    }

    this.lang_code=this.common._lang_code;

    this.actRouter.queryParams.subscribe(params => {
      this.page_id =parseInt(params.page_id);
       if (this.page_id !== null || this.page_id !== '') {
       this.getPageDetails(this.lang_code,this.page_id);
       console.log("page_id",this.page_id)
       }
     });
  }

}
