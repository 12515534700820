import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { ProductService } from '../../shared/services/product.service';
import { CartItem } from 'src/app/modals/cart-item';
import { CartService } from '../../shared/services/cart.service';
// import { MainComponent } from '../main';
// import { MainComponent } from '../../main/main.component';
import { from } from 'rxjs';
import { ProductsService } from '../../../services/products.service';
import { CommonService } from '../../../services/common.service';
import { PubSubService } from 'angular7-pubsub';
// import {MycurrencyPipe} from '../../main/custom.currencypipe';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-home-four',
  templateUrl: './home-four.component.html',
  styleUrls: ['./home-four.component.sass'],
 
 
})
export class HomeFourComponent implements OnInit {
  public banners = [];
  public userLocation:any;
  public currencies = ['SAR', 'USD', 'EUR', 'CNY'];
  public currency: any;
  public flags = [
    { name: 'English', image: 'assets/images/flags/gb.svg' },
    { name: 'German', image: 'assets/images/flags/de.svg' },
    { name: 'French', image: 'assets/images/flags/fr.svg' },
    { name: 'Russian', image: 'assets/images/flags/ru.svg' },
    { name: 'Turkish', image: 'assets/images/flags/tr.svg' }
  ]
  currentDate
  public flag: any;
  category_id
  lang_code = this.Common._lang_code
  products: Product[];
  langes = [];
  indexProduct: number;
  shoppingCartItems: CartItem[] = [];
  wishlistItems: Product[] = [];
  popularproducts: any[];
  Featuredproducts: any[];
  CategoryproductsInHome: any[];
  lastpro: any[];
  lang: any;
  CategoryInHomedatadone=false;
  public slides = [
    { title: 'Huge sale', subtitle: 'Up to 70%', image: 'assets/images/carousel/banner1.jpg' },
    { title: 'Biggest discount', subtitle: 'Check the promotion', image: 'assets/images/carousel/banner2.jpg' },
    { title: 'Biggest sale', subtitle: 'Dont miss it', image: 'assets/images/carousel/banner3.jpg' },
    { title: 'Our best products', subtitle: 'Special selection', image: 'assets/images/carousel/banner4.jpg' },
    { title: 'Massive sale', subtitle: 'Only for today', image: 'assets/images/carousel/banner5.jpg' }
  ];
  public advertiseImg='';
  public url_target='';
  public advertiseImgUrl='';
  public imagAdvertisePath='';
  public advertiseImg2='';
  public url_target2='';
  public advertiseImgUrl2='';


  constructor(private productService: ProductService, private Common: CommonService,

    private cartService: CartService, private common: CommonService, private product: ProductsService, private pubsub: PubSubService, ) {
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    this.imagAdvertisePath=this.common._hostName+"uploads/advertisements/";
   
   
    this.pubsub.$sub('popularproducts').subscribe((from) => {
      // this.sidenavOpened = false;
      // console.log("home four component constactor");
      this.getPopularProducts(from);
      this.getFeaturedProducts(from);
      this.getCategoryproductsInHome(from);
      this.getLastProducts(from);
  });
   

  }
  getFeaturedProducts(lang_code) {
    this.product.getFeaturedProducts(lang_code).subscribe((data) => {
      if (data) {
        this.Featuredproducts = data.data;
       
        // console.log("Featuredproducts",this.Featuredproducts);
       
      }
    });
  }
  getCategoryproductsInHome(lang_code) {
    this.product.getCategoryproductsInHome(lang_code).subscribe((data) => {
      if (data) {
        this.CategoryproductsInHome = data.data;
        this.CategoryInHomedatadone = true;
        // console.log("CategoryproductsInHome",this.CategoryproductsInHome)
      }
    });
  }
  getPopularProducts(lang_code) {
    this.product.getPopularProducts(lang_code).subscribe((data) => {
      if (data) {
        this.popularproducts = data.data;
// console.log("getPopularProducts",this.popularproducts);
      }
    });
  }
  
  getLastProducts(lang_code) {
    this.product.getLatestproducts(lang_code).subscribe((data) => {
      if (data) {
        this.lastpro = data.data;

      }
    });
  }
  getAllLanguages() {
    // console.log("home component");
    this.Common.getAllLanguages().subscribe((data) => {
      if (data.data) {
        this.langes = data.data;
        // console.log("langes", this.langes);


      }
    });
  }

  // : http://192.168.1.120/china_price/api/web-api/products.php?action=getPopularProducts&amp;lang_code=ar
 
 
  getCenterAdvertisements() {
    this.common.getCenterAdvertisements().subscribe((data) => {
      if (data) {
        // console.log("data data data Center Center Center",data);
        let nandNum= Math.floor(Math.random() * data.length) ;
        this.advertiseImg=this.imagAdvertisePath + data[nandNum].img;
        this.advertiseImgUrl=data[nandNum].url;
        this.url_target=data[nandNum].url_target;
      }else
      {
        this.advertiseImg='';
      }
    });
  }

  getBottomAdvertisements() {
    this.common.getBottomAdvertisements().subscribe((data) => {
      if (data) {
        //  console.log("data data bottom getBottomAdvertisements ",data);
        let nandNum= Math.floor(Math.random() * data.length) ;
        this.advertiseImg2=this.imagAdvertisePath + data[nandNum].img;
        this.advertiseImgUrl2=data[nandNum].url;
        this.url_target2=data[nandNum].url_target;
      }
      else{
        this.advertiseImg2='';
      }
    });
  }


  ngOnInit() {

       this.getCenterAdvertisements();
       this.getBottomAdvertisements();
     
    // console.log("this is home four component");


    if(localStorage.getItem('langflag') !=null)
    {
      if(localStorage.getItem('langflag') === 'English'){
        this.common._lang_code='en';
      //  console.log("this is home four component and lang code en" );
      }
      else{
        this.common._lang_code='ar';
        // console.log("this is home four component and lang code ar" );
      }
     
    }
    else {
     
     this.common._lang_code='ar';
    //  console.log("this is home four component and lang code ar by default" );
    }

    this.lang_code=this.common._lang_code;


  //   this.productService.getBanners()
  //     .subscribe(
  //       data => this.banners = data
  //     );



  //   this.productService.getProducts(this.category_id)
  //     .subscribe(
  //       (product: Product[]) => {
  //         this.products = product
  //       }
  //     )
  //   this.currency = this.currencies[0];
  //   this.flag = this.flags[0];

  this.getPopularProducts(this.lang_code);
  this.getFeaturedProducts(this.lang_code);
  this.getCategoryproductsInHome(this.lang_code);
  this.getLastProducts(this.lang_code);


  //   this.productService.popproducts(this.lang_code)
  //     .subscribe(
  //       (producthjhjhjh) => {
  //         this.popularproducts = producthjhjhjh['data'];
  //         console.log(this.popularproducts, 'dddddd')
  //       }

  //     )



  //   // this.currencypipe.transform(this.currentDate, 'YYYY-MM-DDTHH:mm')
  // }








  // public changeCurrency(currency) {
  //   this.currency = currency;
  // }
  // public changeLang(flag) {
  //   // this.flag = flag;
  //   this.getAllLanguages();
  //   this.flag = this.langes;
  //   console.log(this.flag, "55wwwwwwwwwwwwwflag55")
  }

}
