import { Component, OnInit ,ElementRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CartItem } from 'src/app/modals/cart-item';
import { CartService } from '../../shared/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import {  ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.sass']
})
export class CartComponent implements OnInit {

  public datasendcorrect: boolean = false;
  public userLogin: boolean = false;
  public validdata: boolean = false;
  public validdatapaymethod: boolean = false;
  public imageUrl: string;
  public shipping_companyimageUrl: string;
  public cartItems: Observable<CartItem[]> = of([]);
  public shoppingCartItems: CartItem[] = [];

  public buyProductsToadmin: any[] = [];
  public shipping_companies: any[] = [];

  step1: any;
  step2: any;
  step3: any;

  step1active: any;
  step2active: any;
  step3active: any;
  public loadingImgUrl:string;

  pay_method: string = 'cash';
  paymentMessagecorrect:boolean=false;

 
  // Card Data 
  card_number:string='';
  expiry_date:string='';
  card_holder :string='';
 
  cvv :string='';
  loading:boolean=false;
  


  amount: number;
  delivey_price: number=0;
  order_products_weight: number=0;
  price0_5: number=0;
  price0_5_online: number=0;
  price5_any: number=0;
  price5_any_online: number=0;

  saveOrderProgress:boolean=false;

  user_name: string = '';
  oaddress: string = '';

  ostate: string = '';
  otown: string = '';
  checkoutId: string = '';
  currentURL: string = window.location.origin+'/'+'checkpaymentstatus';//
  checkoutIddata: boolean = false;
  opostcode: string = '';
  home_description: string = '';
  ocontent: string = ''; // extra data
  shipping_company ='';

  currentUser: any;
  order_pending: any;
  usre_id: string = '';


  constructor(private cartService: CartService,private elementRef:ElementRef,private actRouter: ActivatedRoute, public commService: CommonService, public user: UserService, private router: Router) {
    this.imageUrl = this.commService._hostName + 'uploads/products/';
    this.shipping_companyimageUrl = this.commService._hostName + 'uploads/shipping_companies/';
    this.loadingImgUrl=this.commService._hostName +'uploads/loading.gif';
    this.step1 = true;
    this.step1active = true;
   
  }

  chooseShippingCompany(){
    
      var company = this.shipping_companies.find(obj => {
        return obj.id ===this.shipping_company; 
      });
      if(company){
      this.order_products_weight=0;
      if (this.shoppingCartItems.length > 0) {
        for (let igg = 0; igg < this.shoppingCartItems.length; igg++) {
            let ttt=this.shoppingCartItems[igg].product.product_weight*this.shoppingCartItems[igg].quantity;
            this.order_products_weight=Number(this.order_products_weight+ttt);
        }
      }

      if(this.order_products_weight <= 5){
        
            this.price0_5=company.price0_5;
            this.price0_5_online=company.price0_5_online;
            this.price5_any=0;
            this.price5_any_online=0;

            this.delivey_price=company.price0_5;
      }else{
            this.price0_5=0;
            this.price0_5_online=0;
            this.price5_any=company.price5_any;
            this.price5_any_online=company.price5_any_online;

            this.delivey_price=company.price5_any;
      }
    console.log("this.delivey_price",this.delivey_price);
      }
  }

  ngOnInit() {
    
    this.cartItems = this.cartService.getItems();
    this.cartItems.subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    this.getTotal().subscribe(amount => this.amount = amount);
  
      this.getAllShippingCompanies();
     
    if (this.user.getToken() !== null) {
      this.currentUser = JSON.parse(this.user.getCurrentUser());
      this.usre_id = this.currentUser.id;
      this.user_name = this.currentUser.full_name;
      this.userLogin = true;
    }
    console.log("on init in cart component");
    if(localStorage.getItem("order_pending_information") != null ){
          this.order_pending=JSON.parse(localStorage.getItem("order_pending_information"));
          this.user_name=this.order_pending.user_name;
          this.oaddress=this.order_pending.address;
          this.opostcode=this.order_pending.post_code;
          this.delivey_price=this.order_pending.delivey_price > 0 ?this.order_pending.delivey_price:0;
          this.ostate=this.order_pending.country;
          this.otown=this.order_pending.city;
          this.pay_method=this.order_pending.pay_method;
          this.home_description=this.order_pending.home_description;
          this.ocontent=this.order_pending.additional_inf;
          this.shipping_company=this.order_pending.shipping_company;
          if(this.shipping_company > '0'){
               this.chooseShippingCompany();
          }
          this.Gostep3();
    }

    this.actRouter.paramMap.subscribe((params: ParamMap) => {
      console.log("message",params);
      var mm=params.get('message');
    console.log("message mm",mm);
      
       if (mm ==='fail') {
        this.paymentMessagecorrect=true;
        this.pay_method='cash';
       }
     });


  }

  Gostep2() {
    if (this.userLogin === true) {
      this.step1 = false;
      this.step2 = true;
      this.step3 = false;
      this.step1active = true;
      this.step2active = true;
      this.step3active = false;
      window.scrollTo(0,0);
      window.scrollTo(0,0);
      window.scrollTo(0,0);
    }
    else{
      localStorage.setItem("previousUrl", '/home/cart');
      this.router.navigate(['/pages/my-account']);
    }
  }

  Gostep1() {

    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step1active = true;
    this.step2active = false;
    this.step3active = false;
  }

  Gostep3() {
  
    if (this.ostate == "" || this.opostcode == "" || this.oaddress == "" || this.shipping_company == "") {
      
      this.validdata = true;
      return;
    } else {
     

      if (this.userLogin === true) {
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.step1active = true;
        this.step2active = true;
        this.step3active = true;
        window.scrollTo(0,0);
        window.scrollTo(0,0);
        window.scrollTo(0,0);
      }
      else{
        var order_pending = {
          "user_name": this.user_name,
          "address": this.oaddress,
          "post_code": this.opostcode,
          "country": this.ostate,
          "city": this.otown,
          "home_description": this.home_description,
          "additional_inf": this.ocontent,
          "shipping_company": this.shipping_company,
          "delivey_price": this.delivey_price,
          "pay_method": this.pay_method,
        }

        localStorage.setItem("order_pending_information", JSON.stringify(order_pending));
        localStorage.setItem("previousUrl", '/home/cart');
        this.router.navigate(['/pages/my-account']);
      }


     
    }
  }


  getAllShippingCompanies(){

    this.commService.getAllShippingCompanies().subscribe((data) => {
      if (data) {
       this.shipping_companies=data.data;
       console.log("shipping_companies",this.shipping_companies);
          if(this.shipping_company > '0'){
            this.chooseShippingCompany();
          }
      }
      else {
        this.shipping_companies=[];
      }

    });
  }


  // Remove cart items
  public removeItem(item: CartItem) {
    this.cartService.removeFromCart(item);
  }
  // Increase Product Quantity
  public increment(product: any, quantity: number = 1) {
    this.cartService.updateCartQuantity(product, quantity);
  }
  // Decrease Product Quantity
  public decrement(product: any, quantity: number = -1) {
    this.cartService.updateCartQuantity(product, quantity);
  }
  // Get Total
  public getTotal(): Observable<number> {
    
    return this.cartService.getTotalAmount();
  }
  
  public getDeliveryPrice () {
    return Number(this.delivey_price);
  }
   public getOrderTotalOrderPrice () {
    this.getTotal().subscribe(amount => this.amount = amount);
    return Number(Number(this.amount)+Number(this.delivey_price));
  }

  seandOrderToAdmin() {
    this.saveOrderProgress=true;

  // cash or bank payment order
    // this.checkOuth();

    if (this.pay_method == 'cash'){
      if(this.order_products_weight <= 5){
        this.delivey_price=this.price0_5;
      }else{
        this.delivey_price=this.price5_any;
      }
    }else{
       if(this.order_products_weight <= 5){
        this.delivey_price=this.price0_5_online;
      }else{
        this.delivey_price=this.price5_any_online;
      }
    }

    if (this.userLogin === true) {
      if (this.pay_method != 'cash' && this.pay_method != 'bank'   ) {
        this.validdatapaymethod = true;
        return;
      }
      else {
        var user = {
          "user_id": this.usre_id,
          "token": this.user.getToken(),
          "user_name": this.user_name,
          "address": this.oaddress,
          "post_code": this.opostcode,
          "country": this.ostate,
          "city": this.otown,
          "home_description": this.home_description,
          "additional_inf": this.ocontent,
          "shipping_company": this.shipping_company
        }
        console.log("shoppingCartItems",this.shoppingCartItems)
        if (this.shoppingCartItems.length > 0) {
          this.buyProductsToadmin = [];
          for (let i = 0; i < this.shoppingCartItems.length; i++) {
            let productToAdmin = {
              "product_id": this.shoppingCartItems[i].product.id,
              "quantity": this.shoppingCartItems[i].quantity,
              "name": this.shoppingCartItems[i].product.name,
              "price": this.shoppingCartItems[i].product.price,
              "total": this.shoppingCartItems[i].product.price * this.shoppingCartItems[i].quantity + '',
            };
            console.log("productToAdmin",productToAdmin)
            this.buyProductsToadmin.push(productToAdmin);
          }
          this.getTotal().subscribe(amount => this.amount = amount);
          let body = {
            "user": user,
            "products": this.buyProductsToadmin,
            "lang_code": this.commService._lang_code,
            "general_total": Number(Number(this.amount)+Number(this.delivey_price)),
            "delivey_price": this.delivey_price,
            "currency": localStorage.getItem("currency_code"),
            "pay_method": this.pay_method,
          }
          this.saveOrderProgress=true;
          this.commService.seandOrderToAdmin(body).subscribe((data) => {
            if (data.data) {
              this.saveOrderProgress=false;
              // this.datasendcorrect = true;

              this.resetInputs();
              this.removeAllFromCard();
              localStorage.removeItem("order_pending_information");
              this.seandOrderToAdminMessages(data.data,this.usre_id);
              this.cartService.orderSuccess = true;
              this.router.navigate(['order-success']);
            }
            else {
              this.buyProductsToadmin = [];
              this.saveOrderProgress=false;
            }
            this.saveOrderProgress=false;
          });
        }
      }
    }
    else {

      this.saveOrderProgress=false;
      var order_pending = {
        "user_name": this.user_name,
        "address": this.oaddress,
        "post_code": this.opostcode,
        "country": this.ostate,
        "city": this.otown,
        "home_description": this.home_description,
        "additional_inf": this.ocontent,
        "delivey_price": this.delivey_price,
        "shipping_company": this.shipping_company,
        "pay_method": this.pay_method,
      }
      localStorage.setItem("order_pending_information", JSON.stringify(order_pending));
      localStorage.setItem("previousUrl", '/home/cart');
      this.router.navigate(['/pages/my-account']);
    }

  }
  resetInputs() {

    this.oaddress = "";
    this.opostcode = "";
    this.ostate = "";
    this.otown = "";
    this.ocontent = "";
    this.shipping_company = "";
    this.buyProductsToadmin = [];
    this.pay_method='cash';
    this.home_description='';
    this.saveOrderProgress=false;
  } 
  
  seandOrderToAdminMessages(order_id,user_id) {
    let body = {
      "user_id": user_id,
      "order_id": order_id,
      "token": this.user.getToken(),
    }

    this.commService.seandOrderToAdminMessages(body).subscribe((data) => {
      if (data.data) {
      console.log("seand Order ToAdminMessages success")
      }
      else {
        console.log("seand Order ToAdminMessages error")
      }

    });
   
  }
  removeAllFromCard() {

    for (let i = 0; i < this.shoppingCartItems.length; i + 2) {
      this.cartService.removeFromCartAll(this.shoppingCartItems[i])
    }
    this.buyProductsToadmin = [];

  }

  changePaymentType(){
  
    this.checkoutIddata=false;


    if (this.pay_method == 'cash'){
      if(this.order_products_weight <= 5){
        this.delivey_price=this.price0_5;
      }else{
        this.delivey_price=this.price5_any;
      }
    }else{
       if(this.order_products_weight <= 5){
        this.delivey_price=this.price0_5_online;
      }else{
        this.delivey_price=this.price5_any_online;
      }
    }

    if(this.pay_method == 'credit' ||this.pay_method == 'mastercard' ){
     
      this.loading=true;
      let order_pendingnn = {
        "user_name": this.user_name,
        "address": this.oaddress,
        "post_code": this.opostcode,
        "country": this.ostate,
        "city": this.otown,
        "delivey_price":  this.delivey_price,
        "home_description": this.home_description,
        "additional_inf": this.ocontent,
        "shipping_company": this.shipping_company,
        "pay_method": 'credit',
      }
     
        localStorage.setItem("order_pending_information", JSON.stringify(order_pendingnn));
        this.getTotal().subscribe(amount => this.amount = amount);
      let bodygg = {
        "user_id": this.usre_id,
        "amount": (Number(this.amount) +Number(this.delivey_price)),
        "email": "user"+this.usre_id+"@gmail.com",
        "street1": this.oaddress,
        "city": this.otown,
        "state": this.otown,
        "givenName": this.user_name,
        "surname": this.user_name,
        "country": "SA"
        
      }
     
      this.commService.prepareCheckout(bodygg).subscribe((data) => {
        if (data) {
        console.log("prepareCheckout",data);
        this.checkoutId=data.data;
       
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.src = 'https://oppwa.com/v1/paymentWidgets.js?checkoutId='+this.checkoutId;
        this.elementRef.nativeElement.appendChild(s);
        this.checkoutIddata=true;
       
        setTimeout(()=>{
          this.loading=false;
        }, 1000);
       
        }
        else{
        }
      });
    }
    else if(this.pay_method == 'mada'){
     
      this.loading=true;
      let order_pendingnn = {
        "user_name": this.user_name,
        "address": this.oaddress,
        "post_code": this.opostcode,
        "country": this.ostate,
        "city": this.otown,
        "state": this.otown,
        "home_description": this.home_description,
        "additional_inf": this.ocontent,
        "delivey_price": this.delivey_price,
        "shipping_company": this.shipping_company,
        "pay_method": 'mada',
      }
     
        localStorage.setItem("order_pending_information", JSON.stringify(order_pendingnn));
        this.getTotal().subscribe(amount => this.amount = amount);
      let bodygg = {
        "user_id": this.usre_id,
        "amount": (Number(this.amount) +Number(this.delivey_price)),
        "email": "user"+this.usre_id+"@gmail.com",
        "street1": this.oaddress,
        "city": this.otown,
        "givenName": this.user_name,
        "surname": this.user_name,
        "country": "SA"
      }
     
      this.commService.prepareCheckoutMada(bodygg).subscribe((data) => {
        if (data) {
        console.log("prepareCheckoutMada",data);
        this.checkoutId=data.data;
    
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.src = 'https://oppwa.com/v1/paymentWidgets.js?checkoutId='+this.checkoutId;
        this.elementRef.nativeElement.appendChild(s);
        this.checkoutIddata=true;
       
        setTimeout(()=>{
          this.loading=false;
        }, 1000);
       
        }
        else{
        }
      });
    }
    else{

            // this.pay_method = 'cash'
    }
  } 


}
