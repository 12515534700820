import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '../../../shared/services/cart.service';
// import {commonser}
// import { Router, NavigationEnd } from '@angular/router';
// import {ProductsService} from '../../../services/products.service';
import {CommonService} from 'src/app/services/common.service';
import {ProductsService} from 'src/app/services/products.service';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-popular-products',
  templateUrl: './popular-products.component.html',
  styleUrls: ['./popular-products.component.sass']
})
export class PopularProductsComponent implements OnInit {

  public products: Product[];
  public product: Product = {};
  popularproducts=[];
   constructor(private productService: ProductService,public translate: TranslateService,private Cart: CartService,
    private Common:CommonService,private productstore:ProductsService,private router: Router,private actRouter: ActivatedRoute,
    private Router:Router) { }

    
  getPopularProducts(lang_code){
    this.productstore.getPopularProducts(lang_code).subscribe((data) => {
      if (data) {
        this.popularproducts = data.data;
        console.log("popularproducts",this.popularproducts);

      }
    });
  }
 
   ngOnInit() {
     console.log("1111")
     this.actRouter.paramMap.subscribe((params: ParamMap) => {

      let category_id = params.get('id');
      this.productService.getProducts(category_id).subscribe(product => this.products = product);
      // category_id
      //  this.catlogsearch:product
      console.log("z444",category_id)})
    }
    
    //  this.getPopularProducts();
   
}
