import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/components/shared/services/product.service';
// import { ActivatedRoute, Params } from '@angular/router';
import { Product, ColorFilter } from 'src/app/modals/product.model';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, style, transition, animate } from '@angular/animations';
// import { ProductsService } from '../../../../../services/products.service';
// import { ProductsComponent } from '../../products.component';
import { CommonService } from '../../../../services/common.service';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { PubSubService } from 'angular7-pubsub';
@Component({
  selector: 'app-product-left-sidebar',
  templateUrl: './product-left-sidebar.component.html',
  styleUrls: ['./product-left-sidebar.component.sass']
})
export class ProductLeftSidebarComponent implements OnInit {
  public sidenavOpen: boolean = true;
  public getNoData: boolean = false;
  public animation: any;   // Animation
  public sortByOrder: string = '';   // sorting
  public page: any;
  public tagsFilters: any[] = [];
  public viewType: string = 'grid';
  public viewCol: number = 25;
  public colorFilters: ColorFilter[] = [];

  public items: Product[] = [];
  public allItems: Product[] = [];
  public products: Product[] = [];
  public tags: any[] = [];
  public colors: any[] = [];
  loading:boolean=true;
  loadingImgUrl:string='';
  category_id
  lang_code = this.common._lang_code
  constructor(private Common:CommonService,private productService: ProductService, private route: ActivatedRoute, private common: CommonService, private pubsub: PubSubService,public router: Router ) {
    this.loadingImgUrl=this.Common._hostName +'uploads/loading.gif';
    this.pubsub.$sub('categoryProducts').subscribe((from) => {
     this.ngOnInit();
    });
  }



  // Get current product tags
  public getTags(products) {
    var uniqueBrands = []
    var itemBrand = Array();
    products.map((product, index) => {
      if (product.tags) {
        product.tags.map((tag) => {
          const index = uniqueBrands.indexOf(tag);
          if (index === -1) uniqueBrands.push(tag);
        })
      }
    });
    for (var i = 0; i < uniqueBrands.length; i++) {
      itemBrand.push({ brand: uniqueBrands[i] })
    }
    this.tags = itemBrand
  }

  // Get current product colors
  public getColors(products) {
    var uniqueColors = []
    var itemColor = Array();
    products.map((product, index) => {
      if (product.colors) {
        product.colors.map((color) => {
          const index = uniqueColors.indexOf(color);
          if (index === -1) uniqueColors.push(color);
        })
      }
    });
    for (var i = 0; i < uniqueColors.length; i++) {
      itemColor.push({ color: uniqueColors[i] })
    }
    this.colors = itemColor
  }




  getPopularProducts(lang_code) {
    this.productService.popproducts(lang_code).subscribe((data) => {
      if (data) {
        // this.popularproducts = data.data;
        console.log("666667778");

      }
    });
  }


  ngOnInit() {
   
    this.loading=true;
console.log("category / products componant url",this.router.url)
    if(localStorage.getItem('langflag') !=null)
    {
      if(localStorage.getItem('langflag') === 'English'){
        this.common._lang_code='en';
       console.log("this is product left side component and lang code en" );
      }
      else{
        this.common._lang_code='ar';
        console.log("this is product left side component and lang code ar" );
      }
    }
    else {
     this.common._lang_code='ar';
     console.log("this is product left side component and lang code ar by default" );
    }


    this.route.params.subscribe(
      (params: ParamMap) => {
        const category = params['id'];
        this.loading=true;
        this.getNoData=false;
        this.productService.getProductByCategory(category).subscribe(products => {
          if(products){
            this.allItems = products;
            this.loading=false;
            console.log("products in first method",this.allItems);
            if(this.allItems.length == 0){
              console.log("no data or products");
              this.getNoData=true;
            }
            this.products = products.slice(0.8);
            this.getTags(products)
            this.getColors(products)
          }
          else{
            console.log("no data or products");
            this.getNoData=true;
            this.loading=false;
          }
         
        })
      }
    );



    // this.actRouter.paramMap.subscribe((params: ParamMap) => {
    //   let category_id = params.get('id');
    //   // this.getCatProductsDetails('ar', category_id);



    //      console.log("category_id", category_id)

    //   console.log("zzzzzzxxxxxxxx", this.catlogsearch)

    //   // (category_id)

    // })



/*


    // 
    console.log("kkjhioyoi")
    this.productService.popproducts(this.lang_code);
    this.route.paramMap.subscribe((params: ParamMap) => {

      let category_id = params.get('id');

      // this.getCatProductsDetails('ar', category_id);
      console.log("products in second method befor");
      this.productService.getProducts(category_id);
      console.log("consoleconsoleconsolec,category_id", category_id);
      console.log("products in second method after");
      // category_id
      // console.log("category_id999999", category_id)

      // console.log("zzzzzzxxxxxxxx", this.catlogsearch)})
    })
*/
  }



  public changeViewType(viewType, viewCol) {
    this.viewType = viewType;
    this.viewCol = viewCol;
  }
  // Animation Effect fadeIn
  public fadeIn() {
    this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
    this.animation = 'fadeOut';
  }

  // Update tags filter
  public updateTagFilters(tags: any[]) {
    this.tagsFilters = tags;
    this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }



  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
    this.sortByOrder = val;
    this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }

  // Initialize filetr Items
  public filterItems(): Product[] {
    return this.items.filter((item: Product) => {
      const Colors: boolean = this.colorFilters.reduce((prev, curr) => { // Match Color
        if (item.colors) {
          if (item.colors.includes(curr.color)) {
            return prev && true;
          }
        }
      }, true);
      const Tags: boolean = this.tagsFilters.reduce((prev, curr) => { // Match Tags
        if (item.tags) {
          if (item.tags.includes(curr)) {
            return prev && true;
          }
        }
      }, true);
      return Colors && Tags; // return true
    });

  }

  public onPageChanged(event) {
    this.page = event;
    this.allItems;
    window.scrollTo(0, 0);
  }


  // Update price filter
  //   public updatePriceFilters(price: any) {
  //     let items: any[] = [];
  //     this.products.filter((item: Product) => {
  //         if (item.price >= price[0] && item.price <= price[1] )  {
  //            items.push(item); // push in array
  //         }
  //     });
  //     this.items = items;
  // }


  // Update price filter
  public updatePriceFilters(price: any) {
    console.log(price);
    console.log(this.products);


    this.allItems = this.products.filter((item: Product) => {
      return item.price >= price.priceFrom && item.price <= price.priceTo
    });
    console.log(this.products);

  }

  onBrendsChanged(newBrend) {
    console.log(newBrend);
    this.allItems = newBrend === 'all' ? this.products : this.products.filter(

      item => item.brand === newBrend
    )
    console.log(this.allItems);


  }
}
