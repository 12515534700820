import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { PubSubService } from 'angular7-pubsub';

@Component({
  selector: 'app-reject-email',
  templateUrl: './reject-email.component.html',
  styleUrls: ['./reject-email.component.sass']
})
export class RejectEmailComponent implements OnInit {
  page = []
  donePages: boolean = false;
  emailResult: any[] = []
  done:boolean=false;
  email;
  public lang_code=''; 
  
  constructor(private common: CommonService,private pubsub:PubSubService ,private actRouter: ActivatedRoute,private router:Router) { 

    this.lang_code=this.common._lang_code;
    

  }


  rejectEmailSubscriber(email) {
    console.log("rejectEmailSubscriber method",this.emailResult)
    this.common.rejectEmailSubscriber(email).subscribe((data) => {
      if (data.data==true) {
        this.emailResult['title']='Unsubscribe';
        this.emailResult['description']='Subscription canceled successfully';

        this.done = true;
      }
      else if(data.data=='notexit'){
        this.emailResult['title']='Unsubscribe';
        this.emailResult['description']='Sorry, this email is not subscribed';
        
      }
    });
  }


  ngOnInit() {



    console.log("rejected email componant");
    if(localStorage.getItem('langflag') !=null)
    {
      if(localStorage.getItem('langflag') === 'English'){
        this.common._lang_code='en';
       console.log("rejected email component and lang code en" );
      }
      else{
        this.common._lang_code='ar';
        console.log("rejected email and lang code ar" );
      }
    }
    else {
     this.common._lang_code='ar';
     console.log("rejected email and lang code ar by default" );
    }

    this.lang_code=this.common._lang_code;

    this.actRouter.paramMap.subscribe((params: ParamMap) => {
      this.email = params.get('email');
    
      if (this.email !== null || this.email !== '') {
        this.rejectEmailSubscriber(this.email);
        
           
      }
        else 
      {
        this.router.navigate(['/home']);
      }
    
    });


    // this.actRouter.queryParams.subscribe(params => {
    //   this.email =params.email;
    //   console.log("email",this.email);
    //   console.log("params",params);
    //    if (this.email !== null || this.email !== '') {
    //   //  this.getPageDetails(this.lang_code,this.email);
    //    console.log("emal",this.email)
    //    }
    //  });
  }

}
