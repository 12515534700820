import { Component, OnInit, Input, EventEmitter,Output } from '@angular/core';
// import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
// import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { CartItem } from 'src/app/modals/cart-item';
// import { CartService } from '../../shared/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {MatDialog, MatDialogConfig} from "@angular/material";
import {OrderDialogComponent} from "../order-dialog/order-dialog.component";
@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.sass']
})
export class MyOrdersComponent implements OnInit {
 
  private orderDetails:any={};
  private currentUser:any;
  private user_id='';
  private page:number=1;
  private myOrdersCount:number=0;
  private myitemperpage:number=0;
  private token='';
  public datadone:boolean=false;
  private allmyOrders:any[]=[];
  loading:boolean=true;
  public imageUrl:string;
  public loadingImgUrl:string;
  mmodel:any;
  current_page: number;
  previous;
  next;
  pages = [];
  aTotal;
  resultObj = {};
  closeResult: string;
  public lang_code='ar'
  constructor(private dialog: MatDialog,private user:UserService, private modalService: NgbModal,private router: Router, public commService: CommonService, public userService: UserService) {
      this.imageUrl=this.commService._hostName+'uploads/products/';
      this.loadingImgUrl=this.commService._hostName +'uploads/loading.gif';
    }
    openDialog(order_id) {
        this.orderDetails={};
        let order_idd=parseInt(order_id);
        if(order_id != null && order_id > 0 && this.user_id !=null && this.user_id !=''){
          let data={
            "user_id":this.user_id,
            "order_id":order_idd,
            "lang_code":this.lang_code
          }
          this.user.getOneOrderDetails(data).subscribe((data) => {
            if (data) {
              if(data.data){
                this.orderDetails=data.data;
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.data =this.orderDetails;
                this.dialog.open(OrderDialogComponent, dialogConfig);
              console.log("order details dilog",this.orderDetails);
              }
              else{
              }
            }
            else{
                console.log("not order");
            }
        });
        }
  }

   

   checkOuth(){
    if(this.userService.getToken() !== null){
      this.currentUser=JSON.parse(this.userService.getCurrentUser());
      this.user_id=this.currentUser.id;
      this.token=this.currentUser.authentication_code;
    }
    else{
      this.user_id='';
      this.token='';
      this.currentUser={};
      this.router.navigate(['/pages/my-account']);
    }
}

onClickGetSomeOrder(getpage){
  this.page=parseInt(getpage);
  console.log("this.page",this.page)
  this.pagination(this.myOrdersCount,this.page,this.myitemperpage,10);
}


   getMyOrdersCount(){
     this.loading=true;
   
    if(this.user_id !='' && this.token !=''){
      let data={
        "user_id":this.user_id,
      }
      this.userService.getMyOrdersCount(data).subscribe((data) => {
        if (data) {
          if(data.data){
            this.myOrdersCount=data.data.myOrdersCount;
            this.myitemperpage=data.data.myitemperpage;
            this.pagination(this.myOrdersCount,this.page,this.myitemperpage,10);
          }
          else{
          }
        }
        else{
            console.log("not order");
        }
    });
    }
   }
   getSomeOrders(data){
   
    if(this.user_id !='' && this.token !=''){
      this.userService.getMyOrders(data).subscribe((data) => {
        if (data) {
          if(data.data){
            this.datadone=true;
            this.loading=false;
            this.allmyOrders=data.data;
            console.log("allmyOrders",this.allmyOrders);
          }
          else{
            this.datadone=false;
            this.loading=false;
            this.allmyOrders=[];
          }
        }
        else{
            console.log("not order");
        }
    });
    }
   }

  

  ngOnInit() {
    this.checkOuth();
    this.getMyOrdersCount();
            if(localStorage.getItem('langflag') !=null)
          {
            if(localStorage.getItem('langflag') === 'English'){
              this.commService._lang_code='en';
              console.log("this is OrderDialogComponent component and lang code en" );
            }
            else{
              this.commService._lang_code='ar';
              console.log("this is OrderDialogComponent component and lang code ar" );
            }
          }
          else {
            this.commService._lang_code='ar';
            console.log("this is OrderDialogComponent component and lang code ar by default" );
          }
          this.lang_code=this.commService._lang_code;
     }



  pagination(aTotal, page, aItemsPerPage, aLinksPerPage = 10) {
    // console.log("tatal", aTotal);
    let num_pages, first, last, left_offset, from, to, start = 0;

    if (aTotal && aTotal > aItemsPerPage) {

      num_pages = Math.ceil(aTotal / aItemsPerPage);
      this.current_page = parseInt(page);
      this.current_page = (this.current_page < 1) ? 1 : (this.current_page > num_pages ? num_pages : this.current_page);

      left_offset = Math.ceil(aLinksPerPage / 2) - 1;
      first = this.current_page - left_offset;
      first = (first < 1) ? 1 : first;

      last = first + aLinksPerPage - 1;
      last = (last > num_pages) ? num_pages : last;

      first = last - aLinksPerPage + 1;
      first = (first < 1) ? 1 : first;

      this.pages = this.commService.range(first, last, 1);

      // Previous, First links
      if (this.current_page > 1) {
        this.previous = this.current_page - 1;
      } else {
        this.previous = null;
      }

      // Next, Last links
      if (this.current_page < num_pages) {

        this.next = this.current_page + 1;
      } else {
        this.next = null;
      }

      page = (page < 1) ? 1 : page;
      start = (page - 1) * aItemsPerPage;

      from = (aTotal > 0) ? start + 1 : start;
      to = (aTotal > (start + aItemsPerPage)) ? start + aItemsPerPage : aTotal;

    }

    let body = {
      "user_id": this.user_id,
      "page": page,
    }

    this.getSomeOrders(body);

    console.log("this.current_page",this.current_page);
    this.resultObj = {
      pages: this.pages,
      page: this.current_page,
      total: aTotal,
      previous: this.previous,
      next: this.next,
      from: from,
      to: to,
      aItemsPerPage: aItemsPerPage,
    }
  }

}
