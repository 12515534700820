import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscriber } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Product } from 'src/app/modals/product.model';
import { MatSnackBar } from '@angular/material';
import { map } from 'rxjs/operators';
import { ProductsService } from '../../../services/products.service';
import { CommonService } from '../../../services/common.service';


// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("compareItem")) || [];
// let  popproducts
let categoryarr:any=[]
@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public currency: string = 'USD';
  public catalogMode: boolean = false;
  category_id
  private _url: string = "assets/data/";
  public url = "assets/data/banners.json";
 public lang_code='';
  public compareProducts: BehaviorSubject<Product[]> = new BehaviorSubject([]);
  public observer: Subscriber<{}>;
  getAllCategories: any;
  private _url2: string = this.common._hostName + 'web-api/products.php?action='
  constructor(private httpClient: HttpClient, public snackBar: MatSnackBar, private productstore: ProductsService,
    private common: CommonService) {

    this.compareProducts.subscribe(products => products = products);
    // _url2 = this.common._hostName + 'web-api/products.php?action='
    this.lang_code=this.common._lang_code;

  }
  // category_id=false
  private products(category_id): Observable<Product[]> {
    if(localStorage.getItem('langflag') !=null)
    {
      var lang_code=localStorage.getItem('lang_code');
    }
    else{
      var lang_code='ar';
    }
    return this.httpClient.get<Product[]>(this.common._hostName+'web-api/products.php?action=getCatProductsDetails&lang_code='+lang_code+'&category_id='+category_id);
  }

  

  private popularproducts(lang_code) {
    return this.httpClient.get<any[]>(this._url2 + 'getPopularProducts&lang_code=' + lang_code);

  }

  
  public Featuredproducts(lang_code) {
    return this.httpClient.get<any[]>(this._url2 + 'getFeaturedProducts&lang_code=' + lang_code);

  }
 
  private latestproducts(lang_code) {
    return this.httpClient.get<any[]>(this._url2 + 'getLatestProducts&lang_code=' + lang_code);

  }


  public banners(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.url);
  }


  // Get Banners
  public getBanners() {
    return this.banners();
  }

  
  public getProducts(category_id):Observable<Product[]> {
   
    return this.products(category_id);
 
  }
  // public getsearch(): Observable<Product[]> {
  //   return this.search();
  // }

  public popproducts(lang_code) {
    return this.popularproducts(lang_code);
  }

  public fetproducts(lang_code) {
    console.log('667')
    return this.Featuredproducts(lang_code);

  }
  public lastproducts(lang_code) {
    console.log('latestproducts')
    return this.latestproducts(lang_code);

  }
  // Get Products By Id
  public getProduct(id: number): Observable<Product> {
    return this.products(this.category_id).pipe(map(items => {
      return items.find((item: Product) => { return item.id === id; });

    }));
    // return this.products.find(product=> product.id === id);
    // getProductDetails(lang_code,product_id){
    //   console.log('getProductDetails')
    //   return this.http.get<any>(this._url2 + 'getProductDetails&lang_code=' + lang_code + '&product_id=' + product_id)

    // }
    // return this.httpClient.get<Product>(this._url + 'product-' + id + '.json');
  }


  /*
---------------------------------------------
----------  Compare Product  ----------------
---------------------------------------------
*/

  // Get Compare Products
  public getComapreProducts(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(products);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // If item is aleready added In compare
  public hasProduct(product: Product): boolean {
    const item = products.find(item => item.id === product.id);
    return item !== undefined;
  }

  // Add to compare
  public addToCompare(product: Product): Product | boolean {
    let message, status;
    var item: Product | boolean = false;
    if (this.hasProduct(product)) {
      item = products.filter(item => item.id === product.id)[0];
      const index = products.indexOf(item);
      this.snackBar.open('The product  ' + product.name + ' already added to comparison list.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });

    } else {
      if (products.length < 4)
        products.push(product);
      message = 'The product ' + product.name + ' has been added to comparison list.';
      status = 'success';
      this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });

    }
    localStorage.setItem("compareItem", JSON.stringify(products));
    return item;
  }

  // Removed Product
  public removeFromCompare(product: Product) {
    if (product === undefined) { return; }
    const index = products.indexOf(product);
    products.splice(index, 1);
    localStorage.setItem("compareItem", JSON.stringify(products));
  }

  // Get Products By category
  public getProductByCategory(category: string): Observable<Product[]> {
    return this.products(category).pipe(map(items =>
      items.filter((item: Product) => {
    
          return item
     

      })
    ));
  }

}
