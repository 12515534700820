import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../../../../services/products.service';
import { CommonService } from 'src/app/services/common.service';
// import { PubSubService } from 'angular7-pubsub';
// import { SidebarMenuService } from '../shared/sidebar/sidebar-menu.service';
// import { SidenavMenu } from '../shared/sidebar/sidebar-menu.model';
import { Router, NavigationEnd } from '@angular/router';
// import {TranslateHttpLoader} from '@ngx-translate/http-loader'; 
import { TranslateService } from '@ngx-translate/core';
import { PubSubService } from 'angular7-pubsub';
// import { ProductsService } from '../../../services/products.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.sass']
})
export class CategoriesComponent implements OnInit {
  myArraycategory:any=[]
  lang_code=this.common._lang_code
  constructor(private productstore:ProductsService,private common:CommonService, private router: Router) { }
  getAllCategories(lang_code) {
    console.log("categories component");
    this.productstore.getAllCategories(lang_code).subscribe((data) => {
      if (data) {
        // this.materialphotos = data.data;
        this.myArraycategory = data.data;
        // this.subcategory=data.data[1];
        // this.subcategory=data.;
        // console.log("NNNNNNNNNNNNNNNNN6666",this.myArraycategory);
        
      }
    });
  }
  ngOnInit() {
    this.getAllCategories(this.lang_code);
  }
  goTocategory(id) {
    this.router.navigate(['/home/products/',id]);
  }
}
