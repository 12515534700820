import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
@Pipe({
    name: 'Mycurrency',
  })
  
  export class MycurrencyPipe implements PipeTransform {
   
    currency_code=localStorage.getItem('currency_code');
    currency_rate=parseFloat(localStorage.getItem('currency_rate'));

    transform(value){
      value =parseFloat(value);
      if(this.currency_code =='SAR'){
        return (value * this.currency_rate).toFixed(2)+ " " +" ر.س  ";
      }
      else if(this.currency_code =='USD'){
        return "$"+" "+ (value * this.currency_rate).toFixed(2);
      }
      else if(this.currency_code =='CNY'){
        return "¥"+" "+(value * this.currency_rate).toFixed(2);
      }
      else if(this.currency_code =='EGP'){
        return "LE"+" "+(value * this.currency_rate).toFixed(2);
      }
      else if(this.currency_code =='EUR'){
        return "€"+" "+(value * this.currency_rate).toFixed(2);
      }
      else if(this.currency_code =='RUB'){
        return "₽"+" "+(value * this.currency_rate).toFixed(2);
      }
      else{
        return (value).toFixed(2)+ " " +" ر.س  ";
      }
      
    }
   
}
