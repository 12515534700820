import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CartService } from '../components/shared/services/cart.service';

@Injectable({
  providedIn: 'root'
})
export class OrderSuccessGuard implements CanActivate {
  constructor(
    private cartService: CartService,
    private router: Router
  ){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.cartService.orderSuccess) {
      return true;
    }else{
      console.log('navigate to hoome');
      
      this.router.navigate(['/home']);
    }
    return false;
      
  }
  
}
