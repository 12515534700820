import { Component, OnInit, Inject } from '@angular/core';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Product } from 'src/app/modals/product.model';
import { CommonService } from '../../../services/common.service';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import {MatDialog, MatDialogConfig} from "@angular/material";

@Component({
  selector: 'app-order-dialog',
  templateUrl: './order-dialog.component.html',
  styleUrls: ['./order-dialog.component.sass']
})
export class OrderDialogComponent implements OnInit {

 
  public products           :   Product[] = [];
  public counter            :   number = 1;
  public variantImage       :   any = '';
  public selectedColor      :   any = '';
  public selectedSize       :   any = '';
  public orderDetails:any={};
  public orderProducts:any[]=[];
  private currentUser:any;
  private user_id='';
  public username='';
  public orderaddress='';
  public databinding:boolean=false;
  
  category_id;
 
  path='';
  constructor(private dialog: MatDialog,private router: Router, private common: CommonService, public user: UserService, public dialogRef: MatDialogRef<OrderDialogComponent>, @Inject(MAT_DIALOG_DATA) public dialogdata) { 
    this.path=this.common._hostName+'uploads/products/';
  }

  ngOnInit() {
   
   this.checkOuth();
   if(localStorage.getItem('langflag') !=null)
   {
     if(localStorage.getItem('langflag') === 'English'){
       this.common._lang_code='en';
      console.log("this is OrderDialogComponent component and lang code en" );
     }
     else{
       this.common._lang_code='ar';
       console.log("this is OrderDialogComponent component and lang code ar" );
     }
   }
   else {
    this.common._lang_code='ar';
    console.log("this is OrderDialogComponent component and lang code ar by default" );
   }
  }

  checkOuth(){
    if(this.user.getToken() !== null){
      this.currentUser=JSON.parse(this.user.getCurrentUser());
      this.user_id=this.currentUser.id;
    }
    else{
      this.user_id='';
      this.currentUser={};
      this.router.navigate(['/pages/my-account']);
    }
}

  public close(): void {
    this.dialogRef.close();
  }


  public goToProductDeatils(product_id) {
    let link='/home/product/'+product_id;
    this.router.navigate([]).then(result => { window.open(link, '_blank'); });
 }


}
