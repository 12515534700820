import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DemoComponent } from './components/demo/demo.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxImgZoomModule } from 'ngx-img-zoom';
import { MainComponent } from './components/main/main.component';
import { AppRoutingModule } from './app-routing.module';
// import { ShopModule } from './components/shop/shop.module';

import { PubSubModule } from 'angular7-pubsub';
// import {TranslateModule} from '@ngx-translate/core';
import {TranslateLoader, TranslateModule ,TranslatePipe} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import { HomeFourComponent } from './components/shop/home-four/home-four.component';
import {MycurrencyPipe} from './components/main/custom.currencypipe';
import { MainCarouselComponent } from './components/shop/main-carousel/main-carousel.component';
// import { NgModule } from '@angular/core';
import { HomeComponent } from './components/shop/home/home.component';
import { PriceComponent } from './components/shop/products/price/price.component';
import { ProductsComponent } from './components/shop/products/products.component';
import { ProductDetailsComponent } from './components/shop/products/product-details/product-details.component';
import { ProductDialogComponent } from './components/shop/products/product-dialog/product-dialog.component';
import { OrderDialogComponent } from './components/pages/order-dialog/order-dialog.component';
import { ProductLeftSidebarComponent } from './components/shop/products/product-left-sidebar/product-left-sidebar.component';
import { ProductVerticalComponent } from './components//shop/products/product-vertical/product-vertical.component';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FlexLayoutModule } from '@angular/flex-layout';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { ProductCarouselComponent } from './components/shop/home/product-carousel/product-carousel.component';
import { from } from 'rxjs';
import { BrandsComponent } from './components/shop/widgets/brands/brands.component';
import { CategoriesComponent } from './components/shop/widgets/categories/categories.component';
import { PopularProductsComponent } from './components/shop/widgets/popular-products/popular-products.component';
import { ProductZoomComponent } from './components/shop/products/product-details/product-zoom/product-zoom.component';
import { ProductComponent } from './components/shop/products/price/product/product.component';
import { ProductTowComponent } from './components/shop/products/price/product-tow/product-tow.component';
// 
// import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";

// import { FlexLayoutModule } from '@angular/flex-layout';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDividerModule} from '@angular/material/divider';
import {MatRadioModule} from '@angular/material/radio';
import {MatListModule} from '@angular/material/list';
import {MatSliderModule} from '@angular/material/slider';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { MatTableModule } from '@angular/material';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterTwoComponent } from './components/shared/footer-two/footer-two.component';
import { MenuComponent } from './components/shared/menu/menu.component';
import { OrderByPipe } from './components/shared/pipes/order-by.pipe';
import { ProductService } from './components/shared/services/product.service';
import { CartService } from './components/shared/services/cart.service';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';

import { BannersComponent } from './components/shared/banners/banners.component';

import { ShoppingWidgetsComponent } from './components/shared/shopping-widgets/shopping-widgets.component';

import { BannersFourComponent } from './components/shared/banners-four/banners-four.component';
import { BlogSectionComponent } from './components/shared/blog-section/blog-section.component';
import { BannerPromotionComponent } from './components/shared/banner-promotion/banner-promotion.component';

import { CategoriesMenuComponent } from './components/shared/categories-menu/categories-menu.component';
import { CategoriesSectionComponent } from './components/shared/categories-section/categories-section.component';
// 
import { BlogDetailsComponent } from './components/blog/blog-details/blog-details.component';
import { BlogColumnComponent } from './components/blog/blog-column/blog-column.component';
import { BlogListComponent } from './components/blog//blog-list/blog-list.component';
// 
 
import { CartComponent } from './components/pages/cart/cart.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { WishlistComponent } from './components/pages/wishlist/wishlist.component';
import { CompareComponent } from './components/pages/compare/compare.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
// import { PagesRoutingModule } from './components/pages/pages-routing.module';
// import { SharedModule } from './components/shared/shared.module';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyAccountComponent } from './components/pages/my-account/my-account.component';
import { ResetPasswordComponent } from './components/pages/reset-password/reset-password.component';
import { MyOrdersComponent } from './components/pages/my-orders/my-orders.component';
// /my-account/my-account.component
import { FaqComponent } from './components/pages/faq/faq.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { RejectEmailComponent } from './components/pages/reject-email-subscriber/reject-email.component';
import { CheckPaymentStatus } from './components/pages/check-payemnt-status/check-payemnt-status.component';
import { TokenInterceptorService } from './../token-interceptor.service';
// import { HttpClientModule ,HTTP_INTERCEPTORS } from '@angular/common/http'; 

// import { BlogModule } from '../blog/blog.module';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { RouteReuseStrategy } from '@angular/router';
import { ProductCarouselTowComponent } from './components/shop/home/product-carousel-tow/product-carousel-tow.component';
import { OrderSuccessComponent } from './components/pages/order-success/order-success.component';

// import { CustomReuseStrategy} from './CustomReuseStrategy-strategy';
// import { HttpInterceptorProviders } from './services/httpInterceptors';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    MycurrencyPipe,
    AppComponent,
    DemoComponent,
    MainComponent,
    HomeComponent,
    MainCarouselComponent,
    ProductsComponent,
    PriceComponent,
    ProductComponent,
    ProductTowComponent,
    ProductDetailsComponent,
    ProductDialogComponent,
    OrderDialogComponent,
    ProductLeftSidebarComponent,
    ProductVerticalComponent,
  
    ProductComponent,
    ProductCarouselComponent,
    ProductCarouselTowComponent,
    BrandsComponent,
    CategoriesComponent,
    PopularProductsComponent,
    HomeFourComponent,
    ProductZoomComponent,
    HeaderComponent,
    // FooterComponent,
    MenuComponent,
    SidebarComponent,
    OrderByPipe,
    BannersComponent,

    ShoppingWidgetsComponent,
    // HeaderThreeComponent,
    BannersFourComponent,
    BlogSectionComponent,
    BannerPromotionComponent,
    // HeaderFourComponent,
    CategoriesMenuComponent,
    CategoriesSectionComponent,
    FooterTwoComponent,
    // 
    BlogDetailsComponent,
    BlogColumnComponent,
    BlogListComponent,
    ProductDialogComponent,
    OrderDialogComponent,
    // 
    CartComponent,
    ContactComponent,
    WishlistComponent,
    CompareComponent,
    CheckoutComponent,
    MyAccountComponent,
    ResetPasswordComponent,
    MyOrdersComponent,
    FaqComponent,
    AboutUsComponent,
    RejectEmailComponent,
    CheckPaymentStatus,
 
    ErrorPageComponent,
 
    OrderSuccessComponent

  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    NgbModule,
    // SharedModule,
    // ShopModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxImgZoomModule,
    CommonModule,
    // ShopRoutingModule,
 
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxPaginationModule,
    // 
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatListModule,
    MatSliderModule,
    MatExpansionModule,
    MatMenuModule,
    MatTableModule,
    MatRadioModule,
    MatDialogModule,
    MatChipsModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatSelectModule,
    MatTabsModule,
    MatDividerModule,
    MatCardModule,

    FlexLayoutModule,
    BrowserAnimationsModule,
    NgxImageZoomModule.forRoot() ,// <-- Add this line
    TranslateModule.forRoot({
     
    }),
    // TranslatePipe,
    PubSubModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    // TranslatePipe,
    // PubSubModule.forRoot(),

  ],
  exports: [
    CommonModule,
    MatButtonModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatListModule,
    MatExpansionModule,
    MatMenuModule,
    MatTableModule,
    MatSliderModule,
    MatRadioModule,
    MatDialogModule,
    MatChipsModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatSelectModule,
    MatTabsModule,
    MatDividerModule,
    MatCardModule,
    OrderByPipe,
   
    HeaderComponent,
    // FooterComponent,
    MenuComponent,
    SidebarComponent,
    BannersComponent,
    FlexLayoutModule,

    // HeaderThreeComponent,
    // HeaderFourComponent,
    ShoppingWidgetsComponent,
    BannersFourComponent,
    BlogSectionComponent,
    BannerPromotionComponent,
    CategoriesMenuComponent,
    CategoriesSectionComponent,
    FooterTwoComponent,
    ProductDialogComponent,
    OrderDialogComponent

  ],
  
    entryComponents:[
      ProductDialogComponent,OrderDialogComponent
    ],
  
  providers: [ProductService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
  },
    CartService],
  bootstrap: [AppComponent]
})
export class AppModule { }
